import React, { Fragment, useContext } from "react";
import classes from "../Step1/FooterImages.module.scss";
import ZoomImagePanel from "../imagepanel/zoomable-image";
import { getTranslatedWord } from "../../../metadata/ButtonText";
import { GamestateContext } from "../../../context/GameState/GameStateContext";
import { IsImage } from "../../../utilities/util";
import { IArea } from "./imageMapper";

export interface IFooterImage {
  paths: string[];
  images: string[];
  itemNames: string[];
  footerText: string;
}
interface IFooterMapper extends IFooterImage {
  dimensions: number[];
  areas: IArea[];
  onAreaClick: (index: number) => void;
}
const FooterMapper: React.FC<IFooterMapper> = ({
  paths,
  images,
  itemNames,
  footerText,
  dimensions,
  areas,
  onAreaClick,
}) => {
  const { gameLanguage } = useContext(GamestateContext);

  const [openImage, setOpenImage] = React.useState<string | null>(null);

  const createItems = React.useCallback(() => {
    return paths.map((value, index) => {
      return (
        <div key={index} className={classes["image-container"]}>
          <img
            src={images[index]}
            alt=""
            style={{
              width: "85%",
              height: "100%",
              cursor: "pointer",
            }}
            onClick={(e) => {
              if (IsImage(value)) {
                setOpenImage(value);
              } else {
                window.open(value, "_blank");
              }
            }}
          ></img>
          <span>{getTranslatedWord(itemNames[index], gameLanguage)}</span>
        </div>
      );
    });
  }, []);

  return (
    <Fragment>
      {openImage ? (
        <ZoomImagePanel
          image={openImage || ""}
          defaultScale={0.6}
          onClosePress={() => {
            setOpenImage(null);
          }}
          dimensions={dimensions}
          areas={areas}
          onAreaClick={onAreaClick}
        ></ZoomImagePanel>
      ) : null}
      <div className={classes["footer-images"]}>
        <div className={classes["item-container"]}>{createItems()}</div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            color: "white",
          }}
        >
          <span>{footerText}</span>
        </div>
      </div>
    </Fragment>
  );
};

export default FooterMapper;
