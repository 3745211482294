import React from "react";
import classes from "./backdrop.module.scss";

interface IBackdrop {
  show?: boolean;
  color?: string;
  alpha?: boolean;
  onClick?: any;
  children?: React.ReactNode;

}

const Backdrop: React.FC<IBackdrop> = ({
  show= true,
  color= "#00000",
  alpha= true,
  onClick= () => {},
  children,
}) => {
  const alphaclass = alpha ? "backdrop-black" : "backdrop-black-noalpha";
  return show ? (
    <div
      className={`${classes["backdrop"]} ${classes[alphaclass]}`}
      onClick={() => {
        onClick();
      }}
    >
      {children}
    </div>
  ) : null;
};

export default Backdrop;
