import React from "react";
import classes from "./container.module.scss";
interface IContainer  {
  style?: React.CSSProperties;
  children?:React.ReactNode
}
const GameContainer: React.FC<IContainer> = ({ style,  children }) => {
  return (
    <div className={classes["menu"]} style={style}>
      {children}
    </div>
  );
};

export default GameContainer;
