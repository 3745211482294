import React, { useState, useCallback } from "react";
import { defaultBTGame } from "./BTGameInterface";
import { BTGameProvider } from "./BTGameContext";
import { EnemyShips, FriendlyShips } from "../../container/Enums/Enums";

interface BTGameContainerProps {
  children: React.ReactNode;
}

const BTGameContainer: React.FunctionComponent<BTGameContainerProps> = ({
  children,
}) => {
  const [step10ListOfHitShips, setStep10ListOfHitShips] = useState<(EnemyShips|FriendlyShips)[]>(defaultBTGame.step10ListOfHitShips)
  const [treasureFoundImage, setTreasureFoundImage] = useState<string[]>(defaultBTGame.treasureFoundImage)

  const [recordedLyrics, setRecordedLyrics] = useState<string[]>(
    defaultBTGame.recordedLyrics
  );

  const [isBtGameStep4, setIsBtGameStep4] = useState(
    defaultBTGame.isBtGameStep4
  );

  const [ingredientsAmount, setIngredientsAmount] = useState<string[]>(
    defaultBTGame.ingredientsAmount
  );

  const [ingredientVideo, setIngredientVideo] = useState<string>(
    defaultBTGame.ingredientVideo
  );

  const [playAgainIngredientVideo, setPlayAgainIngredientVideo] =
    useState<number>(defaultBTGame.playAgainIngredientVideo);

  const [isBGAudioPlaying, setIsBGAudioPlaying] = useState<boolean>(
    defaultBTGame.isBGAudioPlaying
  );

  const updateRecordedLyrics = useCallback((lyrics: string[]) => {
    setRecordedLyrics(lyrics);
  }, []);

  const updateIsBtGameStep4 = (state: boolean) => {
    setIsBtGameStep4(state);
  };

  const updateIngredientsAmount = useCallback((amount: string[]) => {
    setIngredientsAmount(amount);
  }, []);

  const updateIngredientVideo = useCallback((videoUrl: string) => {
    setIngredientVideo(videoUrl);
  }, []);

  const updatePlayAgainIngredientVideo = (currentTime: number) => {
    setPlayAgainIngredientVideo(currentTime);
  };

  const updateIsBGAudioPlaying = (playOrPause: boolean) => {
    setIsBGAudioPlaying(playOrPause);
  };

  const updateTreasureFoundImage = (treasureImage: string[]) => {
    setTreasureFoundImage(treasureImage);
  };

  const updateStep10ListOfHitShips = (hitShips: (EnemyShips|FriendlyShips)[]) => {
    setStep10ListOfHitShips(hitShips);
  };

  return (
    <BTGameProvider
      value={{
        step10ListOfHitShips,
        treasureFoundImage,
        recordedLyrics,
        ingredientsAmount,
        ingredientVideo,
        playAgainIngredientVideo,
        isBGAudioPlaying,
        isBtGameStep4,
        updateTreasureFoundImage,
        updateRecordedLyrics,
        updateIsBtGameStep4,
        updateIngredientsAmount,
        updateIngredientVideo,
        updatePlayAgainIngredientVideo,
        updateIsBGAudioPlaying,
        updateStep10ListOfHitShips
      }}
    >
      {children}
    </BTGameProvider>
  );
};

export default BTGameContainer;
