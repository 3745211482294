import React, { useContext, useState } from "react";
import classes from "./menucard.module.scss";
import menu_card from "../../img/menu/menu_card.png";
import active_menu_card from "../../img/menu/menu_card_active.png";
import { NavLink, useNavigate } from "react-router-dom";
import { ROUTE_RESULT } from "../../constants";
import { GamestateContext } from "../../context/GameState/GameStateContext";
import { EGameScreen } from "../../container/Enums/Enums";
import { getTranslatedWord } from "../../metadata/ButtonText";

interface ICard {
  label: string;
  logo_path: string;
  path?: string;
  onClick?: () => void;
}
const Menucard: React.FunctionComponent<ICard> = ({
  label,
  logo_path,
  path = "",
  onClick = () => {},
}) => {
  const { setGameScreen, gameLanguage } = useContext(GamestateContext);
  const [isHover, setHoverState] = useState<boolean>(false);
  const navigate= useNavigate();

  const onMouseEnterHandler = (value: boolean) => {
    setHoverState(value);
  };

  const NavigateToComponent = (): void => {
    switch (path) {
      case ROUTE_RESULT:
        setGameScreen(EGameScreen.Result);
        break;
      default:
       navigate(path);
    }
  };
  return (
    <div className="col-xm-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
      <NavLink
        className={classes["menu-card"]}
        to={path}
        onClick={() => {
          onClick();
        }}
        onMouseEnter={() => {
          onMouseEnterHandler(true);
        }}
        onMouseLeave={() => {
          onMouseEnterHandler(false);
        }}
      >
        <img
          className={`${classes["menu-card-background"]} mt-4`}
          src={isHover ? active_menu_card : menu_card}
          alt="menu card"
        />
        <img
          className={classes["menu-card-logo"]}
          style={{ opacity: isHover ? "1" : "0.5" }}
          src={logo_path}
          alt="how to play logo"
        />
        <p
          className={`${classes["menu-card-label"]} text-uppercase`}
          style={{ opacity: isHover ? "1" : "0.5" }}
        >
          {getTranslatedWord(label, gameLanguage)}
        </p>
      </NavLink>
    </div>
  );
};

export default Menucard;
