import React, { Fragment, useContext, useState } from "react";
import classes from "./Cocktail.module.scss";
import { GamestateContext } from "../../../../context/GameState/GameStateContext";
import InputField from "../../../inputfield/inputfield";

const Cocktail = () => {
  const { setCurrentStepAnswer } = useContext(GamestateContext);
  const [state, setState] = useState<string[]>(["", "", "", ""]);
  console.log(state);
  return (
    <Fragment>
      {state.map((st, index) => {
        return (
          <div key={index} className={classes["container"]}>
            <div style={{ width: "110px" }}>
              <span className={"mr-3"}>Cocktail {index + 1}</span>
            </div>
            {/* <span className={"mr-3"}>Cocktail {index + 1}</span> */}
            <InputField
              //   onKeyDown={onKeyDownEvent}
              onValueChanged={(e) => {
                //setCurrentStepAnswer(e.target.value);
                const updateState = [...state];
                updateState[index] = e.target.value;
                setState([...updateState]);
                setCurrentStepAnswer(updateState.join(" "));
              }}
              onBlur={(e) => {
                const updateState = [...state];
                updateState[index] = e.target.value.trim();
                setState([...updateState]);
                setCurrentStepAnswer(updateState.join(" "));
              }}
              value={state[index].length === 0 ? "" : state[index]}
              style={{ width: "320px" }}
            ></InputField>
          </div>
        );
      })}
    </Fragment>
  );
};

export default Cocktail;
