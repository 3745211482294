import React, { Fragment, useContext } from "react";
import { GamestateContext } from "../../../context/GameState/GameStateContext";
import { getTranslatedWord } from "../../../metadata/ButtonText";
import classes from "../imagepanel/imagepanel.module.scss";
import ZoomImagePanel from "../imagepanel/zoomable-image";
import AreaPanel from "../imagepanel/areaPanel";
// import AudioClip from "./audioClip";
export interface IImagePanel extends React.ImgHTMLAttributes<HTMLImageElement> {
  linkImage: string;
  viewerImage: string;
  footerText?: string;
  audioPath?: string;
  defaultScale?: number;
  isDynamic?: boolean;
}

export interface IArea {
  coords: number[];
  color?: string;
  image?: string;
  link?: boolean;
  pointerCursor?: boolean;
}
export interface IImageWrapper extends IImagePanel {
  dimensions: number[];
  areas: IArea[];
  onAreaClick: (index: number) => void;
}
const ImageMapper: React.FC<IImageWrapper> = ({
  linkImage,
  viewerImage,
  footerText = undefined,
  audioPath = "",
  defaultScale = 0.75,
  isDynamic = false,
  dimensions,
  areas,
  onAreaClick,
  ...rest
}) => {
  const { gameLanguage } = useContext(GamestateContext);
  const [isZoomedOn, showZoomed] = React.useState(false);

  return (
    <Fragment>
      {isZoomedOn ? (
        <ZoomImagePanel
          image={viewerImage}
          defaultScale={defaultScale}
          onClosePress={() => {
            showZoomed(false);
          }}
        ></ZoomImagePanel>
      ) : null}
      <div className={classes["content-container"]} style={rest.style}>
        {false ? null : ( //   ></img> //     }} //       showZoomed(true); //     onClick={() => { //     style={{ minWidth: "25%", maxWidth: "100%", cursor: "pointer" }} //     alt="" //     src={getDynamicImage(viewerImage)} //   <img
          //   <a href={getDynamicImage(linkImage)} target="_blank">
          <Fragment>
            <AreaPanel
              areas={areas}
              dimensions={dimensions}
              onAreaClick={onAreaClick}
              viewerImage={viewerImage}
              linkImage={linkImage}
              style={{ minWidth: "25%", maxWidth: "100%" }}
            ></AreaPanel>
          </Fragment>
        )}

        <div className={classes["content-container-text"]}>
          <span>
            {footerText === undefined
              ? getTranslatedWord("(click to enlarge)", gameLanguage)
              : footerText}
          </span>
        </div>
      </div>
    </Fragment>
  );
};

//export default ImagePanel;

export default React.memo(ImageMapper, function arePropsAreEqual(prev, next) {
  return (
    prev.areas === next.areas &&
    prev.footerText === next.footerText &&
    prev.linkImage === next.linkImage &&
    prev.viewerImage === next.viewerImage &&
    prev.isDynamic === next.isDynamic
  );
});
