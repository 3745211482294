import React, { useState } from "react";
import { PopupProvider } from "./PopupContext";
import { defaultPopupState } from "./PopupStateInterface";

interface PopupContainerProps {
  children: React.ReactNode;
}

const PopupContainer: React.FC<PopupContainerProps> = ({ children }) => {
  const [isLoading, setLoading] = useState<boolean>(
    defaultPopupState.isLoading
  );
  const [hasPopup, setPopup] = useState<React.ReactNode>(
    defaultPopupState.popup
  );

  const showLoading = (value: boolean): void => {
    setLoading(value);
  };
  const showPopup = (box: React.ReactNode): void => {
    setPopup(box);
  };
  return (
    <PopupProvider
      value={{
        isLoading: isLoading,
        showLoading,
        popup: hasPopup,
        showPopup,
      }}
    >
      {children}
    </PopupProvider>
  );
};

export default PopupContainer;
