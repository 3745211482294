import React, { Fragment } from "react";
import ImageMapper, { IImagePanel } from "../game/imageMapper/imageMapper";
import ImageViewer from "../game/imagepanel/imagepanel";
import Step7 from "../../assets/MayDay/Step7a_a.jpg";

const defaultButtonState = [
  { coords: [1100, 1015, 1145, 1055], link: true, pointerCursor: false },
];
const Step7Map: React.FC<IImagePanel> = ({
  linkImage,
  viewerImage,
  footerText,
  defaultScale,
  style,
}) => {
  const [toggle, setToggle] = React.useState(false);

  const onAreaClicked = (index: number) => {
    setToggle(!toggle);
  };

  return (
    <Fragment>
      {toggle ? (
        <ImageViewer
          linkImage={Step7}
          viewerImage={Step7}
          style={style}
          defaultScale={defaultScale}
          onBackButtonClicked={() => {
            onAreaClicked(0);
          }}
        ></ImageViewer>
      ) : (
        <ImageMapper
          linkImage={linkImage}
          viewerImage={viewerImage}
          footerText={footerText}
          style={style}
          defaultScale={defaultScale}
          dimensions={[3036, 1677]}
          areas={[...defaultButtonState]}
          onAreaClick={onAreaClicked}
        ></ImageMapper>
      )}
    </Fragment>
  );
};

export default Step7Map;
