import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../../context/Auth/AuthContext";
import { GamestateContext } from "../../context/GameState/GameStateContext";
import { ROUTE_LOGIN, ROUTE_LOGIN_NL, ROUTE_MENU } from "../../constants";
import { GameLanguages, EGameScreen } from "../Enums/Enums";

interface PrivateRouteProps {
  element: React.ReactElement;
  currentScreen?: EGameScreen;
}

const PrivateRoute: React.FunctionComponent<PrivateRouteProps> = ({
  element,
  currentScreen = EGameScreen.None,
}) => {
  const authValues = useContext(AuthContext);
  const { screen, gameLanguage } = useContext(GamestateContext);

  const RedirectToLogin = (): string => {
    switch (gameLanguage) {
      case GameLanguages.DUTCH:
        return ROUTE_LOGIN_NL;
      default:
        return ROUTE_LOGIN;
    }
  };

  const isAuthenticated = authValues.checkAuthentication();

  if (!isAuthenticated) {
    return <Navigate to={RedirectToLogin()} replace />;
  }

  if (currentScreen === EGameScreen.Question && screen !== EGameScreen.Question) {
    return <Navigate to={ROUTE_MENU} replace />;
  }

  return element;
};

export default PrivateRoute;
