import { Routes, Route } from "react-router-dom";
import React, { Suspense } from "react";
import { CircularSpinner } from "../../components/Spinner/Spinner";
import PrivateRoute from "../PrivateRoute/PrivateRoute";
import {
  ROUTE_MENU,
  ROUTE_GAME_RULES,
  ROUTE_RESUME_INTRO,
  ROUTE_LOGIN,
  ROUTE_GAME_STEP,
  ROUTE_RESULT,
  ROUTE_HOW_TO_PLAY,
  ROUTE_LEADERBOARD,
  ROUTE_TEAM_NAME,
  ROUTE_START_INTRO,
  ROUTE_LOGIN_NL,
  ROUTE_CODE_RESULT_VIEWER,
} from "../../constants";
import { EGameScreen } from "../Enums/Enums";

const LoginContainer = React.lazy(() => import("../Login/Login"));
const LoginNLContainer = React.lazy(() => import("../Login/LoginDutch"));
const GameMenuContainer = React.lazy(() => import("../GameMenu/Menu"));
const GameRuleContainer = React.lazy(() => import("../GameIntro/GameRules"));
const GameStartContainer = React.lazy(() => import("../GameIntro/GameIntro"));
const GameResumeIntroContainer = React.lazy(
  () => import("../ResumeIntro/ResumeIntro")
);
const GameStepContainer = React.lazy(() => import("../GameScreen/GameScreen"));
const ResultContainer = React.lazy(() => import("../GameScreen/ResultScreen"));
const Leaderboard = React.lazy(() => import("../Leaderboard/Leaderboard"));
const CodeResultViewer = React.lazy(
  () => import("../Leaderboard/CodeResultViewer")
);
const HowToPlay = React.lazy(() => import("../HowToPlay/HowToPlay"));
const TeamNameContainer = React.lazy(() => import("../Team/TeamName"));

const GameRoutes: React.FunctionComponent = () => {
  return (
    <Suspense
      fallback={<CircularSpinner backdropAlpha={false}></CircularSpinner>}
    >
      <Routes>
        <Route
          path={ROUTE_GAME_STEP}
          element={
            <PrivateRoute
              currentScreen={EGameScreen.Question}
              element={<GameStepContainer />}
            />
          }
        />
        <Route
          path={ROUTE_RESULT}
          element={<PrivateRoute element={<ResultContainer />} />}
        />
        <Route
          path={ROUTE_MENU}
          element={<PrivateRoute element={<GameMenuContainer />} />}
        />
        <Route
          path={ROUTE_LEADERBOARD}
          element={<PrivateRoute element={<Leaderboard />} />}
        />
        <Route
          path={ROUTE_TEAM_NAME}
          element={<PrivateRoute element={<TeamNameContainer />} />}
        />
        <Route
          path={ROUTE_HOW_TO_PLAY}
          element={<PrivateRoute element={<HowToPlay />} />}
        />
        <Route
          path={ROUTE_GAME_RULES}
          element={<PrivateRoute element={<GameRuleContainer />} />}
        />
        <Route
          path={ROUTE_START_INTRO}
          element={<PrivateRoute element={<GameStartContainer />} />}
        />
        <Route
          path={ROUTE_RESUME_INTRO}
          element={<PrivateRoute element={<GameResumeIntroContainer />} />}
        />
        <Route
          path={ROUTE_CODE_RESULT_VIEWER}
          element={<CodeResultViewer />}
        />
        <Route
          path={ROUTE_LOGIN_NL}
          element={<LoginNLContainer />}
        />
        <Route
          path={ROUTE_LOGIN}
          element={<LoginContainer />}
        />
      </Routes>
    </Suspense>
  );
};

export default GameRoutes;
