export interface IPopupState {
  isLoading: boolean;
  popup: React.ReactNode;
  showLoading: (value: boolean) => void;
  showPopup: (box: React.ReactNode) => void;
}

export const defaultPopupState: IPopupState = {
  isLoading: false,
  popup: null,
  showLoading: (value: boolean) => {},
  showPopup: (box: React.ReactNode) => {},
};
