import React, { Fragment } from "react";
import FooterImage from "../components/game/Step1/FooterImages";
import ImageViewer from "../components/game/imagepanel/imagepanel";
import PdfIcon from "../assets/BBHGame/pdficon.png";
import Recording from "../components/game/recordings/recordingPanel";
import { IQuestion } from "./TTMeta";
import { EQuestionType, GameLanguages } from "../container/Enums/Enums";

import Step1 from "../assets/BBHGame/Step1.jpg";
import Step1_2 from "../assets/BBHGame/Step1_2.jpg";
import Step2 from "../assets/BBHGame/Step2.jpg";
import Step3_1 from "../assets/BBHGame/Step3_1.jpg";
import Step3_2 from "../assets/BBHGame/Step3_2.jpg";
import Step4_1 from "../assets/BBHGame/Step4_1.jpg";
import Step4_2 from "../assets/BBHGame/Step4_2.jpg";
import Step5_1 from "../assets/BBHGame/Step5_1.jpg";
import Step5_2 from "../assets/BBHGame/Step5_2.jpg";
import Step7_1 from "../assets/BBHGame/Step7_1.jpg";
import Step7_22 from "../assets/BBHGame/Step7_22.jpg";
import Step7_3 from "../assets/BBHGame/Step7_3.jpg";
import Step8_1 from "../assets/BBHGame/Step8_1.jpg";
import Step8_2 from "../assets/BBHGame/Step8_2.jpg";
import Step9_2 from "../assets/BBHGame/Step9_2.jpg";
import Step9_3 from "../assets/BBHGame/Step9_3.jpg";
import Step10 from "../assets/BBHGame/Step10.jpg";
import Step11a from "../assets/BBHGame/Step11a.jpg";
import Step11b_1 from "../assets/BBHGame/Step11b_1.jpg";
import Step11b_2 from "../assets/BBHGame/Step11b_2.jpg";
import Step11c from "../assets/BBHGame/Step11c.jpg";
import Step11d from "../assets/BBHGame/Step11d.jpg";
import Step11e from "../assets/BBHGame/Step11e.jpg";
import Step11f from "../assets/BBHGame/Step11f.jpg";
import Step12a_1 from "../assets/BBHGame/Step12a_1.jpg";
import Step12f_1 from "../assets/BBHGame/Step12f_1.jpg";
import Step13 from "../assets/BBHGame/Step13.jpg";

export const getGameQuestionsByLanguage = (language?: string): IQuestion[] => {
  switch (language) {
    case GameLanguages.ENUK:
      return Questions;
    case GameLanguages.DUTCH:
      return Questions;
    default:
      return Questions;
  }
};

const Questions: IQuestion[] = [
  {
    Id: "1",
    Type: EQuestionType.Input,
    Question: "How does Ben get into the bank building?",
    Hints: ["", "", ""],
    Options: [""],
    Body: [
      <span key={1} style={{ color: "#7ED321", fontSize: "0.8em" }}>
        INTRO
      </span>,
      <span key={2} className={"mt-2"}>
        Hi team, my name is Ben!
        <br></br> For many many years I've been working as an underpaid cleaner
        at this small bank called Yenombank. Each and every day felt exactly the
        same, until I found a{" "}
        <a
          href={"https://www.yenombank.com.au/docs/blueprintv2.png"}
          target="_blank"
          rel="noreferrer"
          style={{ color: "red" }}
        >
          <u>blueprint</u>
        </a>{" "}
        of the bank building with some notes written on it.
      </span>,
      <span key={3} className={"mt-3"}>
        With your extensive robbery skills and the blueprint, I think it's time
        to get my well-deserved money and look at retirement.
        <br></br>
        But I'm a bit of a clumsy one, so that's where I need your help!
      </span>,
      <span key={5} className={"mt-3"}>
        We will probably not be able to crack all vaults, but we'll try to crack
        as many as we can.
      </span>,
      <ImageViewer
        key={6}
        linkImage={Step1}
        viewerImage={Step1}
        defaultScale={0.55}
        style={{ maxWidth: "40%", minWidth: "25px" }}
      ></ImageViewer>,
      <Fragment key={121}>
        <span
          key={1}
          className={"mt-3"}
          style={{ color: "#7ED321", fontSize: "0.8em" }}
        >
          GETTING IN
        </span>
        <span key={2} className={"mt-2"}>
          Ok, I'm at the bank. It's night time here, so no one should be around.
          <br></br>
          We've got 60 minutes to get in and out of the building and to escape,
          before the next patrol will check the area. I've brought 2 loot bags
          and my access card.
        </span>

        <span className={"mt-3"} key={4}>
          Can you start by sending an email to security? Just say that Ben has
          lost his access card.<br></br>If something goes wrong, at least I can
          say that I lost my access card and someone else used it...
        </span>

        <span className={"mt-3"} key={5}>
          Once you've sent the email, let's focus on getting into the bank.
          <br></br>I'm at the side entrance. Normally I always come through the
          main entrance and this door is locked.<br></br>Here's what I can see.
        </span>

        <ImageViewer
          key={6}
          linkImage={Step1_2}
          viewerImage={Step1_2}
          style={{ maxWidth: "35%", minWidth: "25px" }}
        ></ImageViewer>
      </Fragment>,
    ],

    Content: [Step1],
  },
  {
    Id: "2",
    Type: EQuestionType.Input,
    Question: "What is the 4 digit code to disable the alarm?",
    Hints: ["", "", ""],
    Options: [""],
    InputPattern: "^[0-9]*$",
    InputMaxLimit: 4,
    Body: [
      <span style={{ color: "#7ED321", fontSize: "0.8em" }} key={1}>
        ALARM
      </span>,
      <span className={"mt-2"} key={2}>
        Got it, I'm in!<br></br>
        Next we need to disable the alarm. It needs a 4 digit code to
        deactivate.
      </span>,
      <span key={3} className={"mt-3"}>
        Hmm, wonder what it could be. The manager is always talking about that
        bank robbery in Pennsylvania that happened in the 18th century. Maybe
        that could have something to do with it?
      </span>,
      <ImageViewer
        key={4}
        linkImage={Step2}
        viewerImage={Step2}
        defaultScale={0.6}
        style={{ maxWidth: "60%", minWidth: "250px" }}
      ></ImageViewer>,
    ],
    Content: [Step2],
  },
  {
    Id: "3",
    Type: EQuestionType.Input,
    Question:
      "What is the password to get into the system? (request a hint if you haven't received a response email yet)",
    Hints: ["", "", ""],
    Options: [""],
    Body: [
      <span key={1} style={{ color: "#7ED321", fontSize: "0.8em" }}>
        WIPE SECURITY FOOTAGE
      </span>,
      <span key={2} className={"mt-2"}>
        Awesome team, the alarm is now deactivated!<br></br>
        Let's get to the security control room and wipe all security footage
        and the{" "}
        <a
          href={Step1}
          target="_blank"
          rel="noreferrer"
          style={{ color: "red" }}
        >
          <u>access card</u>
        </a>{" "}
        logs.
      </span>,
      <ImageViewer
        key={4}
        linkImage={Step3_1}
        viewerImage={Step3_1}
        defaultScale={0.6}
        style={{ maxWidth: "60%", minWidth: "250px" }}
      ></ImageViewer>,
      <Fragment key={121}>
        <span key={2} className={"mt-4"}>
          To get into the system I need a password, but I wouldn't have a clue.<br></br>
          Here's a photo of the login screen.
        </span>
        <ImageViewer
          key={4}
          linkImage={Step3_2}
          viewerImage={Step3_2}
          style={{ maxWidth: "60%", minWidth: "250px" }}
        ></ImageViewer>
        <span key={3} className={"mt-4"}>
          The screen says we need to send an email to the security team.<br></br>
          If you've sent an email before we got into the bank, check if you have received an email back from security.<br></br>
          And then use the response email to figure out what the password is.
        </span>
      </Fragment>,
    ],
    Content: [Step3_1],
  },
  {
    Id: "4",
    Type: EQuestionType.Input,
    Question: "What is the code to open the foreign exchange vault?",
    Hints: ["", "", ""],
    Options: [""],
    InputPattern: "^[0-9]*$",
    Body: [
      <span key={1} style={{ color: "#7ED321", fontSize: "0.8em" }}>
        FOREIGN EXCHANGE VAULT
      </span>,
      <span key={3} className={"mt-2"}>
        OK, the footage is deleted, the cameras are disabled and my access card
        usage is removed from the logs. Now let's start finding some loot.
      </span>,
      <span key={2} className={"mt-2"}>
        As I'm not quite sure yet how good your skills are, let's start with the
        easier vaults and work our way up. We'll try the foreign exchange vault
        first.
      </span>,
      <span key={4} className={"mt-2"}>
        Oh and an important thing to remember! We'll want to pack up and get
        out at least 5+ minutes before the time runs out to solve the final challenge. If the time runs
        out and we're still near the bank building, we'll end up with nothing.
      </span>,
      // <ModelViewer key={4} path={"TT/SuitCase/suitcasev1.glb"}></ModelViewer>,
      <ImageViewer
        key={5}
        linkImage={Step4_1}
        viewerImage={Step4_1}
        defaultScale={0.5}
        style={{ maxWidth: "40%", minWidth: "25px" }}
      ></ImageViewer>,
      <Fragment key={121}>
        <span key={1} className={"mt-2"}>
          I'm at the foreign exchange vault and it seems that it needs a code to
          open, what a surprise.<br></br> Not sure how many digits though.
        </span>

        <span key={2} style={{ color: "#7ED321", fontSize: "0.8em" }} className={"mt-4"}>KEYBOARD CHARACTERS</span>
        <span key={3} className={"mt-2"}>
          I noticed that the keyboard has some odd numbers on it, they look like
          Chinese or Japanese.<br></br>I wonder if that has anything to do with
          it.
          <br></br>
          Perhaps the{" "}
          <a
            href={"https://www.yenombank.com.au/docs/blueprintv2.png"}
            target="_blank"
            rel="noreferrer"
            style={{ color: "red" }}
          >
            <u>blueprint</u>
          </a>{" "}
          contains some hints about the length of the code and the code itself.
        </span>

        <span key={4} className={"mt-2"}>
          Let me know what the code is once you've worked that out.
        </span>

        <ImageViewer
          key={5}
          linkImage={Step4_2}
          viewerImage={Step4_2}
          defaultScale={1}
          style={{ maxWidth: "60%", minWidth: "250px" }}
        ></ImageViewer>
      </Fragment>,
    ],
    Content: [Step4_1],
  },
  {
    Id: "5",
    Type: EQuestionType.Input,
    Question: "What is the 4 digit code to open the vault?",
    Hints: ["", "", ""],
    Options: [""],
    InputPattern: "^[0-9]*$",
    InputMaxLimit: 4,
    Body: [
      <span key={1} style={{ color: "#7ED321", fontSize: "0.8em" }}>
        VAULT CRACKED
      </span>,
      <span key={2} className={"mt-2"}>
        That's the correct code, the vault opened. Amazing team, thanks so much!
        There's quite a few different currencies in there, so not sure how much
        loot exactly, but at least $50,000 I'd say.
      </span>,
      <span key={3} className={"mt-2"}>
        Have a look at it yourselves.
      </span>,
      // <ModelViewer key={4} path={"TT/SuitCase/suitcasev1.glb"}></ModelViewer>,
      <ImageViewer
        key={4}
        linkImage={Step5_1}
        viewerImage={Step5_1}
        defaultScale={0.5}
        style={{ maxWidth: "40%", minWidth: "25px" }}
      ></ImageViewer>,
      <Fragment key={121}>
        <span
          className={"mt-2"}
          style={{ color: "#7ED321", fontSize: "0.8em" }}
        >
          TRANSPORT ROOM
        </span>
        <span className={"mt-2"}>
          Great, we've cracked our first vault. Now let's go to the transport
          room vault.
        </span>
        <span className={"mt-2"}>
          This security door needs to be unlocked remotely.<br></br>
          I've managed to unlock the room's vault door from his computer. And
          I've also got you access to his computer. Visit{" "}
          <a
            href={"https://www.yenombank.com.au/private/usr/usr1042/#"}
            target="_blank"
            rel="noreferrer"
            style={{ color: "red" }}
          >
            <u>this address</u>
          </a>{" "}
          to access his machine.
        </span>
        <span className={"mt-2"}>
          The vault needs a 4 digit code. Hopefully he left a reminder of the
          code on his machine, can you check both his desktop and start menu?
          Let me know once you've found it.
        </span>
        <span className={"mt-2"}>
          Oh and don't forget your colouring pencil...
        </span>
        <ImageViewer
          linkImage={Step5_2}
          viewerImage={Step5_2}
          defaultScale={0.5}
          style={{ maxWidth: "40%", minWidth: "25px" }}
        ></ImageViewer>
      </Fragment>,
    ],
    Content: [Step5_1],
  },
  {
    Id: "6",
    Type: EQuestionType.Input,
    Question: "What is the code to open the vault?",
    Hints: ["", "", ""],
    Options: [""],
    Body: [
      <Fragment key={2}>
        <span style={{ color: "#7ED321", fontSize: "0.8em" }}>
          VAULT CRACKED
        </span>
        <span className={"mt-2"}>I'm entering the code now...</span>
        <span className={"mt-2"}>
          It worked, the vault opened. I knew I could count on you!<br></br>
          It contains gold bars, an old painting and a small piece of art. I
          doubt that piece of art is worth anything, but the gold bars and
          painting combined are probably worth $200,000, if not more.
        </span>
        <ImageViewer
          linkImage={Step7_1}
          viewerImage={Step7_1}
          defaultScale={0.5}
          style={{ maxWidth: "40%", minWidth: "25px" }}
        ></ImageViewer>
        <span key={5} style={{ color: "#7ED321", fontSize: "0.8em" }}>
          TELLER STASH
        </span>
        <span key={6} className={"mt-2"}>
          The teller stash vault is quite an interesting vault as it has some
          sort of ciphers.<br></br>I wouldn't know how to open it unfortunately.
          But I'm sure you do!
        </span>
        <span key={7} className={"mt-2"}>
          Here's a photo of the vault.
        </span>
        <ImageViewer
          linkImage={Step7_22}
          viewerImage={Step7_22}
          defaultScale={0.48}
          style={{ maxWidth: "40%", minWidth: "25%" }}
        ></ImageViewer>
      </Fragment>,
      <Fragment key={121}>
        <span className={"mt-2"}>
          I noticed that there's a manufacturer sticker on the side containing
          the model number, let me send you a photo of that as well.
        </span>
        <span className={"mt-2"}>
          Can you scan the QR code?
          <br></br>Then use this to decipher the code for the vault...it needs 3
          numbers.
        </span>
        <ImageViewer
          linkImage={Step7_3}
          viewerImage={Step7_3}
          style={{ maxWidth: "40%", minWidth: "25px" }}
        ></ImageViewer>
      </Fragment>,
    ],

    Content: [Step7_1, Step7_22, Step7_3],
  },
  {
    Id: "7",
    Type: EQuestionType.Input,
    Question: "Where in the building should Ben go to to find a clue?",
    Hints: ["", "", ""],
    Options: [""],
    Body: [
      <Fragment key={3}>
        <span style={{ color: "#7ED321", fontSize: "0.8em" }}>
          VAULT CRACKED
        </span>
        <span className={"mt-2"}>
          Bingo, vault opened. And it has quite a bit of cash in it.<br></br>
          Quickly counting those stacks of dollars, that's $250,000. Here's a
          photo of it!
        </span>
        <ImageViewer
          linkImage={Step8_1}
          viewerImage={Step8_1}
          defaultScale={0.5}
          style={{ maxWidth: "40%", minWidth: "25px" }}
        ></ImageViewer>
        <span className={"mt-2"}>
          But I could definitely use some more money, so the manager's vault is
          next on my list.<br></br>
          Remember to check your remaining time! You probably want to save at
          least 5 minutes to get away.
        </span>
        <span
          className={"mt-4"}
          style={{ color: "#7ED321", fontSize: "0.8em" }}
        >
          MANAGER'S VAULT
        </span>
        <span className={"mt-2"}>
          The manager's vault seems to be a pretty standard vault, with a dial
          that has the numbers 0 to 9 on it.
        </span>
        <span className={"mt-2"}>
          There's no clue around here to what the code could be.<br></br>
          Hopefully someone left a clue somewhere in the building.<br></br>
          Can you have a look at
          the{" "}
          <a
            href={"https://www.yenombank.com.au/docs/blueprintv2.png"}
            target="_blank"
            rel="noreferrer"
            style={{ color: "red" }}
          >
            <u>blueprint</u>
          </a>{" "}
          and tell me to which location in the building I need to go to find a
          clue?
        </span>
        <ImageViewer
          linkImage={Step8_2}
          viewerImage={Step8_2}
          defaultScale={0.5}
          style={{ maxWidth: "40%", minWidth: "25px" }}
        ></ImageViewer>
      </Fragment>,
    ],
    Content: [Step8_1, Step8_2],
  },
  {
    Id: "8",
    Type: EQuestionType.Input,
    Question: "What is the 3 digit code to open the manager's vault?",
    Hints: ["", "", ""],
    Options: [""],
    InputPattern: "^[0-9]*$",
    InputMaxLimit: 3,
    Body: [
      <Fragment key={4}>
        <span style={{ color: "#7ED321", fontSize: "0.8em" }}>PRINTER</span>
        <span className={"mt-2"}>
          Hmm, ok it seems someone left something on the printer.
        </span>
        <ImageViewer
          linkImage={Step9_2}
          viewerImage={Step9_2}
          defaultScale={0.48}
          style={{ maxWidth: "40%", minWidth: "25px" }}
        ></ImageViewer>
        <span className={"mt-4"}>
          Here, take a better look and tell me what you think the code is.
        </span>
        <ImageViewer
          linkImage={Step9_3}
          viewerImage={Step9_3}
          defaultScale={0.38}
          style={{ maxWidth: "40%", minWidth: "25px" }}
        ></ImageViewer>
      </Fragment>,
    ],
    Content: [Step9_2, Step9_3],
  },
  {
    Id: "9",
    Type: EQuestionType.RadioButton,
    Question: "Where should Ben go next?",
    Hints: ["", "", ""],
    Options: ["Safety deposit boxes", "Main vault"],
    Body: [
      <Fragment key={5}>
        <span style={{ color: "#7ED321", fontSize: "0.8em" }}>
          VAULT CRACKED
        </span>
        <span className={"mt-2"}>
          Pure gold! Amazing, 8 gold bars just sitting there ready to be taken
          to their new owner.<br></br>I'm guessing they are worth about
          $640,000!
        </span>
        <ImageViewer
          linkImage={Step10}
          viewerImage={Step10}
          style={{ maxWidth: "40%", minWidth: "25px" }}
        ></ImageViewer>
        <span
          className={"mt-2"}
          style={{ color: "#7ED321", fontSize: "0.8em" }}
        >
          WHAT TO DO NOW?
        </span>
        <span className={"mt-2"}>
          Hopefully we still have some time left to get more loot.
        </span>
        <span className={"mt-2"}>
          We now need to make an important decision. Do we go for the safety
          deposit boxes, which are easier to crack but have less loot? Or for
          the main vault, which is harder to crack, but perhaps worth the extra
          time?
          <br></br>
          Or should we take what we have and just get out of here?
        </span>
      </Fragment>,
    ],
    Content: [Step10],
  },
  {
    Id: "10A",
    Type: EQuestionType.Input,
    Question: "What is the code to open the small safe?",
    Hints: ["", "", ""],
    Options: [""],
    InputPattern: "^[0-9]*$",
    InputMaxLimit: 4,
    Body: [
      <Fragment key={6}>
        <span style={{ color: "#7ED321", fontSize: "0.8em" }}>
          SAFETY DEPOSIT BOXES
        </span>
        <span className={"mt-2"}>
          To get into the safety deposit boxes we need the vault door code and a
          key to a deposit box. They sometimes have a spare key of a deposit box
          in the small safe, before it's shipped to central storage.
        </span>
        <span className={"mt-2"}>
          I've just gone to the security room and am standing in front of a
          small safe, which might contain a key. Here's a photo of it.
        </span>
        <ImageViewer
          linkImage={Step11a}
          viewerImage={Step11a}
          defaultScale={0.4}
          style={{ maxWidth: "40%", minWidth: "25px" }}
        ></ImageViewer>
        <span className={"mt-3"}>
          I recognise those flags from the day I was training to become a navy
          seal. Didn't end up well for me.<br></br>I think they are called flag
          semaphores...<br></br>
          Let me know what the code is and I'll enter it.
        </span>
      </Fragment>,
    ],
    Content: [Step11a],
  },
  {
    Id: "10B",
    Type: EQuestionType.Input,
    Question: "What is the password to continue?",
    Hints: ["", "", ""],
    Options: [""],
    Body: [
      <Fragment key={7}>
        <span style={{ color: "#7ED321", fontSize: "0.8em" }}>PASSWORD</span>
        <span className={"mt-2"}>
          Great, the small safe is open. There's a safety key and a deposit box
          key in there. And also some weird device.<br></br>Here's a photo of
          what's inside the box.
        </span>
        <ImageViewer
          linkImage={Step11b_1}
          viewerImage={Step11b_1}
          style={{ maxWidth: "40%", minWidth: "25px" }}
        ></ImageViewer>
        <span className={"mt-2"}>
          Next we need to open the vault door, which I think is controlled by
          the security team's computer.<br></br>But it needs a password.
        </span>
        <ImageViewer
          linkImage={Step11b_2}
          viewerImage={Step11b_2}
          defaultScale={0.6}
          style={{ maxWidth: "40%", minWidth: "25px" }}
        ></ImageViewer>
      </Fragment>,
    ],
    Content: [Step11b_1, Step11b_2],
  },
  {
    Id: "10C",
    Type: EQuestionType.Input,
    Question: "How many toilets are there in the building?",
    Hints: ["", "", ""],
    Options: [""],
    InputPattern: "^[0-9]*$",
    InputMaxLimit: 2,
    Body: [
      <Fragment key={8}>
        <span style={{ color: "#7ED321", fontSize: "0.8em" }}>QUESTION 1</span>
        <span className={"mt-2"}>
          Ok, now simply go through the questions and answer them correctly to
          find the 3 vault door codes.
        </span>
        <ImageViewer
          linkImage={Step11c}
          viewerImage={Step11c}
          defaultScale={0.5}
          style={{ maxWidth: "40%", minWidth: "25px" }}
        ></ImageViewer>
        <span className={"mt-3"}>
          The first question is: how many toilets does the bank building have?
          <br></br>You might want to have another look at the{" "}
          <a
            href={"https://www.yenombank.com.au/docs/blueprintv2.png"}
            target="_blank"
            rel="noreferrer"
            style={{ color: "red" }}
          >
            <u>blueprint</u>
          </a>
          ...
        </span>
      </Fragment>,
    ],
    Content: [Step11c],
  },
  {
    Id: "10D",
    Type: EQuestionType.Input,
    Question: "What's the number?",
    Hints: ["", "", ""],
    Options: [""],
    InputPattern: "^[0-9]*$",
    InputMaxLimit: 2,
    Body: [
      <Fragment key={10}>
        <span style={{ color: "#7ED321", fontSize: "0.8em" }}>QUESTION 2</span>
        <span className={"mt-2"}>
          Keep going! Answer the other 2 questions to find the 3 vault door
          codes.
        </span>
        <ImageViewer
          linkImage={Step11d}
          viewerImage={Step11d}
          defaultScale={0.4}
          style={{ maxWidth: "40%", minWidth: "25px" }}
        ></ImageViewer>
      </Fragment>,
    ],
    Content: [Step11d],
  },
  {
    Id: "10E",
    Type: EQuestionType.Input,
    Question: "In what year was the painting made?",
    Hints: ["", "", ""],
    Options: [""],
    InputPattern: "^[0-9]*$",
    InputMaxLimit: 4,
    Body: [
      <Fragment key={11}>
        <span style={{ color: "#7ED321", fontSize: "0.8em" }}>QUESTION 3</span>
        <span className={"mt-2"}>
          Last question, if we answer this one correctly we should have the
          loot!
        </span>
        <ImageViewer
          linkImage={Step11e}
          viewerImage={Step11e}
          defaultScale={0.38}
          style={{ maxWidth: "30%", minWidth: "25px" }}
        ></ImageViewer>
      </Fragment>,
    ],
    Content: [Step11e],
  },
  {
    Id: "10F1",
    Type: EQuestionType.RadioButton,
    Question: "Should we try to crack the main vault or escape the bank?",
    Hints: ["", "", ""],
    Options: ["Go to main vault", "Escape the bank"],
    Body: [
      <Fragment key={12}>
        <span style={{ color: "#7ED321", fontSize: "0.8em" }}>
          VAULT CRACKED
        </span>
        <span className={"mt-2"}>
          We've got the recovery code. I'll quickly get to the vault door and
          open it.
        </span>
        <span className={"mt-2"}>
          The code is working, I'm in the vault and found the deposit box that
          matches with one of the keys!<br></br>It seems we've found ourselves
          some beautiful earrings and a ring, both filled with diamonds.
          <br></br>I doubt they will look good on me, but they must be worth at
          least $1,500,000.
        </span>
        <ImageViewer
          linkImage={Step11f}
          viewerImage={Step11f}
          style={{ maxWidth: "40%", minWidth: "25px" }}
        ></ImageViewer>
        <span className={"mt-3"}>
          Ok, only one more vault to crack, also the hardest one. The main
          vault...said to be uncrackable.<br></br>But this could also be a
          moment where we decide to leave with what we've got. What will it be?
        </span>
      </Fragment>,
    ],
    Content: [Step11f],
  },
  {
    Id: "10F2",
    Type: EQuestionType.None,
    Question: "",
    Hints: ["", "", ""],
    Options: [""],
    Body: [
      <Fragment key={13}>
        <span style={{ color: "#7ED321", fontSize: "0.8em" }}>
          VAULT CRACKED
        </span>
        <span className={"mt-2"}>
          We've got the recovery code. I'll quickly get to the vault door and
          open it.
        </span>
        <span className={"mt-2"}>
          The code is working, I'm in the vault and found the deposit box that
          matches with one of the keys!
          <br></br>It seems we've found ourselves some beautiful earrings and a
          ring, both filled with diamonds.<br></br>I doubt they will look good
          on me, but they must be worth at least $1,500,000.
        </span>
        <ImageViewer
          linkImage={Step11f}
          viewerImage={Step11f}
          style={{ maxWidth: "40%", minWidth: "25px" }}
        ></ImageViewer>
        <span className={"mt-3"}>
          We did it team, we've stripped this bank completely.<br></br>Time to
          get out of here and make it back to safety!
        </span>
      </Fragment>,
    ],
    Content: [Step11f],
  },
  {
    Id: "11A",
    Type: EQuestionType.Input,
    Question: "Which item could be useful to crack the main vault?",
    Hints: ["", "", ""],
    Options: [""],
    Body: [
      <Fragment key={14}>
        <span style={{ color: "#7ED321", fontSize: "0.8em" }}>MAIN VAULT </span>
        <span className={"mt-2"}>
          The main vault door is protected by a code.
        </span>
        <span className={"mt-3"}>
          I'm hoping there's some more info on this beast of a vault in the
          manager's office.<br></br>
          I'll do a quick check to see if he has anything useful in his desk drawers.
        </span>
        <ImageViewer
          linkImage={Step12a_1}
          viewerImage={Step12a_1}
          defaultScale={1.0}
          style={{ maxWidth: "60%", minWidth: "250px" }}
        ></ImageViewer>
        <span className={"mt-2"}>
          Here, I've found some stuff in his drawer.
        </span>
      </Fragment>,
    ],
    Content: [Step12a_1],
  },
  {
    Id: "11B",
    Type: EQuestionType.Input,
    Question: "What is the password to open the document?",
    Hints: ["", "", ""],
    Options: [""],
    InputPattern: "^[0-9]*$",
    Body: [
      <Fragment key={15}>
        <span style={{ color: "#7ED321", fontSize: "0.8em" }}>USB DRIVE</span>
        <span className={"mt-2"}>
          Yes, a USB drive. Let me insert the USB drive into a computer.
          <br></br>Hmm, it contains music samples and a password protected
          document.
        </span>
        <Recording
          header={"AUDIO FILE"}
          path={"assests/GameFiles/BBH/recording.mp3"}
          hasSlider={true}
        ></Recording>
        <FooterImage
          paths={[
            `${process.env.PUBLIC_URL}/assests/GameFiles/BBH/Mad_Heaven_Vault_Recovery_Manual_v2.0b.pdf`,
          ]}
          images={[PdfIcon]}
          itemNames={["document.pdf"]}
          footerText={"(click to open)"}
        ></FooterImage>
        <span className={"mt-3"}>
          Can you open the document? Then we might be able to open the main
          vault...
        </span>
      </Fragment>,
    ],
    Content: [],
  },
  {
    Id: "11C",
    Type: EQuestionType.Input,
    Question: "What does Ben need to do as part of step A?",
    Hints: ["", "", ""],
    Options: [""],
    Body: [
      <Fragment key={16}>
        <span style={{ color: "#7ED321", fontSize: "0.8em" }}>STEP A</span>
        <span className={"mt-2"}>
          Ok, now that we have the document open, let me go to the vault door.
          <br></br>It seems the password was 873526.
        </span>
        <FooterImage
          paths={[
            `${process.env.PUBLIC_URL}/assests/GameFiles/BBH/Mad_Heaven_Vault_Recovery_Manual_v2.0b.pdf`,
          ]}
          images={[PdfIcon]}
          itemNames={["document.pdf"]}
          footerText={"(click to open)"}
        ></FooterImage>
        <span className={"mt-3"}>
          I'm at the vault door, let me know what I need to do next and I'll see
          if it works.
        </span>
      </Fragment>,
    ],
    Content: [],
  },
  {
    Id: "11D",
    Type: EQuestionType.Input,
    Question: "What is the code to enter?",
    Hints: ["", "", ""],
    Options: [""],
    InputPattern: "^[0-9]*$",
    InputMaxLimit: 4,
    Body: [
      <Fragment key={17}>
        <span style={{ color: "#7ED321", fontSize: "0.8em" }}>STEP B</span>
        <span className={"mt-2"}>
          Great, one step closer to the millions.<br></br>
          But there are more steps to go. Let's keep going, but keep an eye on
          the remaining time.
        </span>
        <FooterImage
          paths={[
            `${process.env.PUBLIC_URL}/assests/GameFiles/BBH/Mad_Heaven_Vault_Recovery_Manual_v2.0b.pdf`,
          ]}
          images={[PdfIcon]}
          itemNames={["document.pdf"]}
          footerText={"(click to open)"}
        ></FooterImage>
        <span className={"mt-3"}>Time for step B.</span>
      </Fragment>,
    ],
    Content: [],
  },
  {
    Id: "11E",
    Type: EQuestionType.Input,
    Question: "Which word(s) does Ben need to say out loud to open the vault?",
    Hints: ["", "", ""],
    Options: [""],
    Body: [
      <Fragment key={18}>
        <span style={{ color: "#7ED321", fontSize: "0.8em" }}>STEP C</span>
        <span className={"mt-2"}>
          We're almost there, just 1 more step to go.<br></br>
          No time to waste, let's solve step C.
        </span>
        <FooterImage
          paths={[
            `${process.env.PUBLIC_URL}/assests/GameFiles/BBH/Mad_Heaven_Vault_Recovery_Manual_v2.0b.pdf`,
          ]}
          images={[PdfIcon]}
          itemNames={["document.pdf"]}
          footerText={"(click to open)"}
        ></FooterImage>
        <span className={"mt-3"}>It needs a translation.</span>
      </Fragment>,
    ],
    Content: [],
  },
  {
    Id: "11F1",
    Type: EQuestionType.RadioButton,
    Question:
      "Should we try and open the safety deposit boxes or escape the bank?",
    Hints: ["", "", ""],
    Options: ["Go to safety deposit", "Escape the bank"],
    Body: [
      <Fragment key={19}>
        <span style={{ color: "#7ED321", fontSize: "0.8em" }}>
          VAULT CRACKED
        </span>
        <span className={"mt-2"}>
          Oh my! Look at these beauties. 20 Pure gold bars, worth around
          $2,200,000.
        </span>
        <span className={"mt-2"}>
          They are going to be heavy to carry, but they are going to change my
          life! :D :D :D
        </span>
        <ImageViewer
          linkImage={Step12f_1}
          viewerImage={Step12f_1}
          style={{ maxWidth: "40%", minWidth: "25px" }}
        ></ImageViewer>
        <span className={"mt-3"}>
          Only one last vault to crack, providing entrance to the safety deposit
          boxes.<br></br>What do you think team? Try the last one or take our
          loot and get out of here?
        </span>
      </Fragment>,
    ],
    Content: [Step12f_1],
  },
  {
    Id: "11F2",
    Type: EQuestionType.None,
    Question: "",
    Hints: ["", "", ""],
    Options: [""],
    Body: [
      <Fragment key={20}>
        <span style={{ color: "#7ED321", fontSize: "0.8em" }}>
          VAULT CRACKED
        </span>
        <span className={"mt-2"}>
          Oh my! Look at these beauties. 20 Pure gold bars, worth around
          $2,200,000.
        </span>
        <span className={"mt-2"}>
          They are going to be heavy to carry, but they are going to change my
          life! :D :D :D
        </span>
        <ImageViewer
          linkImage={Step12f_1}
          viewerImage={Step12f_1}
          style={{ maxWidth: "40%", minWidth: "25px" }}
        ></ImageViewer>
        <span className={"mt-3"}>
          We did it team, we've stripped this bank completely.<br></br>Time to
          get out of here and make it back to safety!
        </span>
      </Fragment>,
    ],
    Content: [],
  },
  {
    Id: "12",
    Type: EQuestionType.Input,
    Question: "What is the 4-digit code to start the car?",
    Hints: ["", "", ""],
    Options: [""],
    InputPattern: "^[0-9]*$",
    InputMaxLimit: 4,
    Body: [
      <Fragment key={21}>
        <span style={{ color: "#7ED321", fontSize: "0.8em" }}>THE GETAWAY</span>
        <span className={"mt-2"}>Ok, let's get out of here!</span>
        <span className={"mt-2"}>
          But this "borrowed" getaway vehicle is terrible. It has this complex security code to start it.<br></br>
          I've managed to figure code 1 out, which is 7. But this is taking me too long.<br></br>
          And I'm sooo stressed.
        </span>
        <ImageViewer
          linkImage={Step13}
          viewerImage={Step13}
          defaultScale={1.0}
          style={{ maxWidth: "60%", minWidth: "250px" }}
        ></ImageViewer>
        <span className={"mt-3"}>Please help me start the car by finding the rest of the pin code before the police arrives.</span>
      </Fragment>,
    ],
    Content: [Step13],
  },
];
