import React, { Fragment } from "react";
import { EQuestionType, GameLanguages } from "../container/Enums/Enums";
import { IQuestion } from "./TTMeta";
import ImageViewer from "../components/game/imagepanel/imagepanel";
import Recording from "../components/game/recordings/recordingPanel";

import Step1image1 from "../assets/HangoverGame/Step1a.png";
import Step1image2 from "../assets/HangoverGame/Step1b.gif";
import Step2 from "../assets/HangoverGame/Step2.jpg";
import Step3 from "../assets/HangoverGame/Step3.jpg";
import Step4 from "../assets/HangoverGame/Step4.jpg";
import Step5image1 from "../assets/HangoverGame/Step5a.png";
import Step5image2 from "../assets/HangoverGame/Step5b.png";
import Step6image1 from "../assets/HangoverGame/Step6a.png";
import Step6image2 from "../assets/HangoverGame/Step6b.jpg";
import Step7image1 from "../assets/HangoverGame/Step7a.jpg";
import Step7image2 from "../assets/HangoverGame/Step7b.jpg";
import Step8image1 from "../assets/HangoverGame/Step8a.png";
import Step8image2 from "../assets/HangoverGame/Step8b.png";
import Step9image1 from "../assets/HangoverGame/Step9a.jpg";
import Step9image2 from "../assets/HangoverGame/Step9b.jpg";
import Cocktail from "../components/game/questionpanel/JH/Cocktail";

export const getGameQuestionsByLanguage = (language?: string): IQuestion[] => {
  switch (language) {
    case GameLanguages.ENUK:
      return Questions;
    case GameLanguages.DUTCH:
      return Questions;
    default:
      return Questions;
  }
};

const Questions: IQuestion[] = [
  {
    Id: "1",
    Type: EQuestionType.Input,
    Question: "What is the 4 digit code to open the drawer lock?",
    Options: [""],
    InputPattern: "^[0-9]*$",
    InputMaxLimit: 4,
    Content: [Step1image1, Step1image2],
    Body: [
      <Fragment key={1}>
        <span style={{ color: "#7ED321", fontSize: "0.8em" }}>
          THE HANGOVER
        </span>
        <span className={"mt-2"}>
          Ouch, the headache! That was one wild night.<br></br>
          What happened!? This is so embarrassing, I'm handcuffed to the bed.
          I'll never drink again...
        </span>
        <span className={"mt-3"}>
          Oh no! Looking at the clock I just realised that I need to catch a
          flight.<br></br>I need to be at the airport within 60 minutes or I'll
          miss my wedding!<br></br>
          And I don't even know where I am.
        </span>
        <ImageViewer
          linkImage={Step1image1}
          viewerImage={Step1image1}
          footerText={"(Click to enlarge)"}
          defaultScale={1}
          style={{ maxWidth: "45%", minWidth: "40%" }}
        ></ImageViewer>
        <span className={"mt-2"}>
          I can see a business card laying on the bedside table. It's from a bar
          called Lettuce Inn.<br></br>
          There is a{" "}
          <a
            href={"https://www.virtualescape.com.au/lettuceinn"}
            target="_blank"
            rel="noreferrer"
            style={{ color: "red" }}
          >
            <u>website</u>
          </a>{" "}
          listed on the card.
        </span>
        <span className={"mt-2"}>
          Can you open up the website? It might not help me now, but I'm sure it
          will be useful later.
        </span>

        <span
          className={"mt-2"}
          style={{ color: "#7ED321", fontSize: "0.8em" }}
        >
          HELP ME
        </span>
        <span className={"mt-2"}>
          First things first. While the website loads, can you please help me to
          free myself from those handcuffs?
        </span>
        <span className={"mt-3"}>
          It seems the drawer next to me is locked and it requires a 4-digit
          code.<br></br>
          Maybe it contains the key. But the only thing I can see is a TV that
          keeps repeating the same things.<br></br>
          It starts with IT from 1990 and each clip seems to be a few years
          later.
        </span>
        <span className={"mt-2"}>
          Could you have a look at the TV and tell me what you think the code
          could be?<br></br>
          It's probably to do with the question marks.
        </span>
        <ImageViewer
          linkImage={Step1image2}
          viewerImage={Step1image2}
          footerText={"(Click to enlarge)"}
          defaultScale={0.9}
          style={{ maxWidth: "45%", minWidth: "40%" }}
        ></ImageViewer>
      </Fragment>,
    ],
  },
  {
    Id: "2",
    Type: EQuestionType.Input,
    Question: "What is the 5 digit code to open the safe?",
    Options: [""],
    InputPattern: "^[0-9]*$",
    InputMaxLimit: 5,
    Body: [
      <Fragment key={2}>
        <span style={{ color: "#7ED321", fontSize: "0.8em" }}>THE NOTE</span>
        <span className={"mt-2"}>
          Thanks team, the drawer opened and there's a key inside.<br></br>
          Now let me uncuff myself and put some clothes on...
        </span>
        <span className={"mt-3"}>
          Wow, what a mess. And it seems I wasn't alone. Someone left a note on
          the table.<br></br>
          Here, have a closer look.
        </span>
        <ImageViewer
          linkImage={Step2}
          viewerImage={Step2}
          footerText={"(Click to enlarge)"}
          style={{ maxWidth: "65%", minWidth: "40%" }}
          defaultScale={1}
        ></ImageViewer>

        <span className={"mt-3"}>
          I'd better check out that safe. It needs 5 digits.<br></br>
          Can you help me figure out the code for the safe?
        </span>
      </Fragment>,
    ],
    Content: [Step2],
  },
  {
    Id: "3",
    Type: EQuestionType.Input,
    Question: "What is the name of the street where the bar can be found?",
    Options: [""],
    Body: [
      <Fragment key={3}>
        <span style={{ color: "#7ED321", fontSize: "0.8em" }}>THE SAFE</span>
        <span className={"mt-2"}>
          Great! The safe is open and it contains a letter and a lollipop.
        </span>
        <ImageViewer
          linkImage={Step3}
          viewerImage={Step3}
          footerText={"(Click to enlarge)"}
          defaultScale={1}
          style={{ maxWidth: "45%", minWidth: "40%" }}
        ></ImageViewer>

        <span
          className={"mt-2"}
          style={{ color: "#7ED321", fontSize: "0.8em" }}
        >
          MARRIAGE
        </span>
        <span className={"mt-2"}>
          Wait, what!? Did I get married to Candy yesterday!?<br></br>
          It feels like I'm in some sort of movie...I'd better find her and get
          this marriage annulled.
        </span>

        <span className={"mt-2"}>
          But I have no idea where to find her. I think I met her in a bar last
          night.<br></br>
          That card from the Lettuce Inn had a{" "}
          <a
            href={"https://www.virtualescape.com.au/lettuceinn"}
            target="_blank"
            rel="noreferrer"
            style={{ color: "red" }}
          >
            <u>website</u>
          </a>{" "}
          on it. Let's see if it tells us where it's located.
        </span>
        <span className={"mt-2"}>
          We are looking for the street name of the bar. Once I know the street
          name, I'll go and visit it and ask around.
        </span>
      </Fragment>,
    ],
    Content: [Step3],
  },
  {
    Id: "4",
    Type: EQuestionType.Input,
    Question: "What's the condition of entry to get into the bar?",
    Options: [""],
    Body: [
      <Fragment key={4}>
        <span style={{ color: "#7ED321", fontSize: "0.8em" }}>BAR ENTRY</span>
        <span className={"mt-2"}>
          All right, I'm at the bar.<br></br>
          But it seems the door is locked and there is a condition of entry
          written on the wall in some strange characters.<br></br>
          They remind me of that Windows font...the one with a 2 in it...
        </span>

        <span className={"mt-2"}>
          Luckily you guys are pretty clever.<br></br>
          Please help me decipher the condition of entry to get in.
        </span>
        <ImageViewer
          linkImage={Step4}
          viewerImage={Step4}
          footerText={"(Click to enlarge)"}
          style={{ maxWidth: "45%", minWidth: "40%" }}
        ></ImageViewer>
      </Fragment>,
    ],
    Content: [Step4],
  },
  {
    Id: "5",
    Type: EQuestionType.Component,
    Question:
      "What are the names of the 4 cocktails? Use a space between each word.",
    Options: [""],
    CustomInputType: <Cocktail></Cocktail>,
    Body: [
      <Fragment key={5}>
        <span style={{ color: "#7ED321", fontSize: "0.8em" }}>IN THE BAR</span>
        <span className={"mt-2"}>Ok, we're in, awesome!</span>
        <span className={"mt-2"}>
          Steve the bartender recognises me from last night and knows where to
          find Candy.<br></br>
          But he first needs our help.
        </span>
        <ImageViewer
          linkImage={Step5image1}
          viewerImage={Step5image1}
          footerText={"(Click to enlarge)"}
          style={{ maxWidth: "45%", minWidth: "40%" }}
        ></ImageViewer>

        <span
          className={"mt-2"}
          style={{ color: "#7ED321", fontSize: "0.8em" }}
        >
          COCKTAILS
        </span>
        <span className={"mt-2"}>
          His boss texted him, that he had customers complaining yesterday about
          the cocktails he made.<br></br>
          He only started working at the bar recently and is better at drinking
          than at making drinks...
        </span>
        <span className={"mt-2"}>
          He's giving him one more chance.<br></br>
          If we help him figure out what these cocktails are, then he's happy to
          tell us where we can find Candy.
        </span>
        <ImageViewer
          linkImage={Step5image2}
          viewerImage={Step5image2}
          footerText={"(Click to enlarge)"}
          defaultScale={0.5}
          style={{ maxWidth: "25%", minWidth: "20%" }}
        ></ImageViewer>
      </Fragment>,
    ],
    Content: [Step5image1, Step5image2],
  },
  {
    Id: "6",
    Type: EQuestionType.Input,
    Question:
      "What's the total amount the customer needs to pay? No need for the dollar sign.",
    Options: [""],
    Body: [
      <Fragment key={"step6"}>
        <span style={{ color: "#7ED321", fontSize: "0.8em" }}>CANDY</span>
        <span className={"mt-2"}>
          That's one happy bartender!<br></br>
          He told me where Candy works, so I'm on my way...
        </span>
        <ImageViewer
          linkImage={Step6image1}
          viewerImage={Step6image1}
          footerText={"(Click to enlarge)"}
          style={{ maxWidth: "45%", minWidth: "40%" }}
        ></ImageViewer>

        <span className={"mt-2"}>It seems she works at a candy shop.</span>
        <span className={"mt-2"}>
          She tells me that we met at the bar and were quite hungry. So we
          decided to drive to McDonalds.<br></br>
          But we took a wrong turn and ended up at a drive-through church and
          said yes to getting married.
        </span>

        <span className="mt-3" style={{ color: "#7ED321", fontSize: "0.8em" }}>
          CASH
        </span>
        <span className={"mt-2"}>
          She understands that this won't work between us, but is only willing
          to get the marriage annulled if I still give her that diamond ring
          that I promised her.<br></br>
          So we need some hard cash!
        </span>
        <Recording
          header={"AUDIO RECORDING"}
          path={"assests/GameFiles/oldman3.mp3"}
          hasSlider={true}
        ></Recording>

        <span className={"mt-2"}>
          Candy is very busy, but if we help out this customer, then we can keep
          the money and spend it in the casino.<br></br>
          Can you help me figure out how much the customer needs to pay?
        </span>
        <ImageViewer
          linkImage={Step6image2}
          viewerImage={Step6image2}
          footerText={"(Click to enlarge)"}
          defaultScale={0.38}
          style={{ maxWidth: "35%", minWidth: "30%" }}
        ></ImageViewer>
      </Fragment>,
    ],
    Content: [Step6image1, Step6image2],
  },
  {
    Id: "7",
    Type: EQuestionType.Input,
    Question: "What is the next number to bet on?",
    Options: [""],
    InputPattern: "^[0-9]*$",
    InputMaxLimit: 2,
    Body: [
      <Fragment key="step7">
        <span style={{ color: "#7ED321", fontSize: "0.8em" }}>CASINO</span>
        <span className={"mt-2"}>
          Time to hit the casino!<br></br>
          The croupier winks at me and asks me to come to the table...what else
          was I up to last night?
        </span>
        <ImageViewer
          linkImage={Step7image1}
          viewerImage={Step7image1}
          footerText={"(Click to enlarge)"}
          style={{ maxWidth: "45%", minWidth: "40%" }}
        ></ImageViewer>

        <span className={"mt-2"}>
          It appears the croupier and I had the most amazing time last night and
          had whole conversations about our love for LARPing. And we finished a
          bottle of goon on a rooftop.<br></br>
          He's happy to give me a bit of extra "luck" and slipped a note into my
          pocket.
        </span>
        <span className={"mt-2"}>
          The last winning number was 32. Which number should I bet on next?
        </span>
        <ImageViewer
          linkImage={Step7image2}
          viewerImage={Step7image2}
          footerText={"(Click to enlarge)"}
          style={{ maxWidth: "45%", minWidth: "40%" }}
        ></ImageViewer>
      </Fragment>,
    ],
    Content: [Step7image1, Step7image2],
  },
  {
    Id: "8",
    Type: EQuestionType.Input,
    Question:
      "What is the full name of the suspect who has stolen the belongings?",
    Options: [""],
    Body: [
      <Fragment key="step8">
        <span style={{ color: "#7ED321", fontSize: "0.8em" }}>JACKPOT</span>
        <span className={"mt-2"}>
          Jackpot! We've got enough money to get her that ring. Let me go back
          to her and get the marriage annulled.
        </span>
        <span className={"mt-2"}>
          All right, taken care of. Her "tears" disappeared as soon as she got
          that ring.<br></br>
          Now it's time to go to the police station and see if I can get my
          wallet and passport back.
        </span>
        <ImageViewer
          linkImage={Step8image1}
          viewerImage={Step8image1}
          footerText={"(Click to enlarge)"}
          defaultScale={0.55}
          style={{ maxWidth: "35%", minWidth: "30%" }}
        ></ImageViewer>

        <span
          className={"mt-2"}
          style={{ color: "#7ED321", fontSize: "0.8em" }}
        >
          POLICE STATION
        </span>
        <span className={"mt-2"}>
          Got to the police station. Apparently detective Delictus, an old
          friend of mine, has moved to Vegas.<br></br>
          What a coincidence.
        </span>
        <span className={"mt-2"}>
          He doesn't have much time to help me though, but since I've helped him
          before, he's happy to let me have a look at the case.<br></br>
          Can you log in to the{" "}
          <a
            href={"https://www.virtualescape.com.au/lasvegaspd"}
            target="_blank"
            rel="noreferrer"
            style={{ color: "red" }}
          >
            <u>Las Vegas PD portal</u>
          </a>{" "}
          and figure out who the thief is?<br></br>
          Then the detective will send a car over.
        </span>
        <span className={"mt-2"}>The case number is: MRCHOW</span>
        <ImageViewer
          linkImage={"https://www.virtualescape.com.au/lasvegaspd"}
          viewerImage={Step8image2}
          footerText={"(Click to open)"}
          style={{ maxWidth: "45%", minWidth: "40%" }}
        ></ImageViewer>
      </Fragment>,
    ],
    Content: [Step8image1, Step8image2],
  },
  {
    Id: "9",
    Type: EQuestionType.Input,
    Question: "What is the confirmation code you received after checking in?",
    Options: [""],
    Body: [
      <Fragment key="step9">
        <span style={{ color: "#7ED321", fontSize: "0.8em" }}>
          FLIGHT CHECK-IN
        </span>
        <span className={"mt-2"}>
          She looked so innocent on the photo, but apparently is a notorious
          thief.
        </span>
        <span className={"mt-2"}>
          Now that I've got my stuff back, it's time to check-in and fly home.
        </span>
        <ImageViewer
          linkImage={"https://www.virtualescape.com.au/tropico"}
          viewerImage={Step9image1}
          footerText={"(Click to open)"}
          style={{ maxWidth: "45%", minWidth: "40%" }}
        ></ImageViewer>

        <span className={"mt-2"}>
          The only way to check in is{" "}
          <a
            href={"https://www.virtualescape.com.au/tropico"}
            target="_blank"
            rel="noreferrer"
            style={{ color: "red" }}
          >
            <u>online</u>
          </a>
          , and they always make it complicated.
        </span>

        <span className={"mt-2"}>
          This is the last time I need your help. Can you help me check in?
          <br></br>
          Then I'll leave you alone and will be forever grateful!
        </span>
        <span className={"mt-2"}>Here's a photo of my passport.</span>
        <ImageViewer
          linkImage={Step9image2}
          viewerImage={Step9image2}
          footerText={"(Click to enlarge)"}
          defaultScale={0.38}
          style={{ maxWidth: "35%", minWidth: "30%" }}
        ></ImageViewer>
      </Fragment>,
    ],
    Content: [Step9image1, Step9image2],
  },
];
