import React from "react";
import IFrameViewer from "../../iframeviewer/iframeviewer";
import { BT_STEP7_LINK } from "../../../constants";

const Step7Mapper: React.FC = () => {
  return (
    <IFrameViewer
      src={BT_STEP7_LINK}
      style={{ maxWidth: "100%", minWidth: "45%" }}
    ></IFrameViewer>
  );
};

export default Step7Mapper;
