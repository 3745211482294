import React, { useContext, useEffect, useState } from "react";
import IFrameViewer from "../../iframeviewer/iframeviewer";
import { BTGameContext } from "../../../context/BTGameContext/BTGameContext";
import { EnemyShips, FriendlyShips } from "../../../container/Enums/Enums";
import { ENEMY_SHIPS } from "../../../constants";

const Step10Mapper: React.FC = () => {

  const { updateStep10ListOfHitShips, step10ListOfHitShips } = useContext(BTGameContext)
  const [enemyHitShips, setEnemyHitShips] = useState<EnemyShips[]>([])


  useEffect(() => {
    if (enemyHitShips.length > 0) {
      const isAllEnemyShipsHits = ENEMY_SHIPS.every(enemyShip => enemyHitShips.includes(enemyShip))
      if (isAllEnemyShipsHits) {
        const updatedHitShips = [...step10ListOfHitShips, ...enemyHitShips]
        updateStep10ListOfHitShips(updatedHitShips);
      }
    }

    return (() => {
      updateStep10ListOfHitShips([])
    })
  }, [enemyHitShips])

  const onShipHit = (data: string) => {
    const isEnemyShipHit = ENEMY_SHIPS.includes(data as EnemyShips)
    if (isEnemyShipHit) {
      setEnemyHitShips(prev => [...prev, data as EnemyShips])
      return
    }
    else {
      const updatedHitShips = [...step10ListOfHitShips, data as FriendlyShips]
      updateStep10ListOfHitShips(updatedHitShips)
    }
  }


  return (
    <IFrameViewer
      src={'assests/GameFiles/BT/step10/index.html'}
      style={{ maxWidth: "100%", minWidth: "45%" }}
      callback={onShipHit}
      footerText="(click on cannon to fire)"
    ></IFrameViewer>
  );
};

export default Step10Mapper;
