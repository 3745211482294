import { GameLanguages } from "../container/Enums/Enums";

export const getTranslatedWord = (word: string, language: string): string => {
  for (let i = 0; i < ButtonText.length; i++) {
    if (ButtonText[i].word === word) {
      let lang = ButtonText[i].languages;
      for (let j = 0; j < lang.length; j++) {
        if (lang[j].language === language) {
          return lang[j].translation;
        }
      }
    }
  }
  return word;
};
export interface ILanguageTranslation {
  language: string;
  translation: string;
}

export interface IWordTranslation {
  word: string;
  languages: ILanguageTranslation[];
}

const ButtonText: IWordTranslation[] = [
  {
    word: "OUR GAMES",
    languages: [
      {
        language: GameLanguages.ENUK,
        translation: "OUR GAMES",
      },
      {
        language: GameLanguages.DUTCH,
        translation: "MEER SPELLEN",
      },
    ],
  },
  {
    word: "LEADERBOARD",
    languages: [
      {
        language: GameLanguages.ENUK,
        translation: "LEADERBOARD",
      },
      {
        language: GameLanguages.DUTCH,
        translation: "RANGLIJST",
      },
    ],
  },
  {
    word: "HOW TO PLAY",
    languages: [
      {
        language: GameLanguages.ENUK,
        translation: "HOW TO PLAY",
      },
      {
        language: GameLanguages.DUTCH,
        translation: "HOE TE SPELEN",
      },
    ],
  },
  {
    word: "START GAME",
    languages: [
      {
        language: GameLanguages.ENUK,
        translation: "START GAME",
      },
      {
        language: GameLanguages.DUTCH,
        translation: "START SPEL",
      },
    ],
  },
  {
    word: "RESUME GAME",
    languages: [
      {
        language: GameLanguages.ENUK,
        translation: "RESUME GAME",
      },
      {
        language: GameLanguages.DUTCH,
        translation: "HERVAT SPEL",
      },
    ],
  },
  {
    word: "VIEW RESULTS",
    languages: [
      {
        language: GameLanguages.ENUK,
        translation: "VIEW RESULTS",
      },
      {
        language: GameLanguages.DUTCH,
        translation: "BEKIJK RESULTAAT",
      },
    ],
  },
  {
    word: "CONNECTED",
    languages: [
      {
        language: GameLanguages.ENUK,
        translation: "CONNECTED",
      },
      {
        language: GameLanguages.DUTCH,
        translation: "VERBONDEN",
      },
    ],
  },
  {
    word: "LOG OUT",
    languages: [
      {
        language: GameLanguages.ENUK,
        translation: "LOG OUT",
      },
      {
        language: GameLanguages.DUTCH,
        translation: "UITLOGGEN",
      },
    ],
  },
  {
    word: "EXIT GAME",
    languages: [
      {
        language: GameLanguages.ENUK,
        translation: "EXIT GAME",
      },
      {
        language: GameLanguages.DUTCH,
        translation: "VERLAAT SPEL",
      },
    ],
  },
  {
    word: "BACK",
    languages: [
      {
        language: GameLanguages.ENUK,
        translation: "BACK",
      },
      {
        language: GameLanguages.DUTCH,
        translation: "TERUG",
      },
    ],
  },
  {
    word: "RETURN TO MAIN MENU",
    languages: [
      {
        language: GameLanguages.ENUK,
        translation: "RETURN TO MAIN MENU",
      },
      {
        language: GameLanguages.DUTCH,
        translation: "TERUG NAAR MENU",
      },
    ],
  },
  {
    word: "RETURN TO GAME",
    languages: [
      {
        language: GameLanguages.ENUK,
        translation: "RETURN TO GAME",
      },
      {
        language: GameLanguages.DUTCH,
        translation: "TERUG NAAR SPEL",
      },
    ],
  },
  {
    word: "SET TEAM NAME",
    languages: [
      {
        language: GameLanguages.ENUK,
        translation: "SET TEAM NAME",
      },
      {
        language: GameLanguages.DUTCH,
        translation: "VOLGENDE",
      },
    ],
  },
  {
    word: "HOW TO PLAY",
    languages: [
      {
        language: GameLanguages.ENUK,
        translation: "HOW TO PLAY",
      },
      {
        language: GameLanguages.DUTCH,
        translation: "INSTRUCTIES",
      },
    ],
  },
  {
    word: "LOG IN",
    languages: [
      {
        language: GameLanguages.ENUK,
        translation: "LOG IN",
      },
      {
        language: GameLanguages.DUTCH,
        translation: "INLOGGEN",
      },
    ],
  },
  {
    word: "REMAINING",
    languages: [
      {
        language: GameLanguages.ENUK,
        translation: "REMAINING",
      },
      {
        language: GameLanguages.DUTCH,
        translation: "RESTEREND",
      },
    ],
  },
  {
    word: "TIME REMAINING",
    languages: [
      {
        language: GameLanguages.ENUK,
        translation: "TIME REMAINING",
      },
      {
        language: GameLanguages.DUTCH,
        translation: "TIME REMAINING",
      },
    ],
  },
  {
    word: "INCORRECT",
    languages: [
      {
        language: GameLanguages.ENUK,
        translation: "INCORRECT",
      },
      {
        language: GameLanguages.DUTCH,
        translation: "INCORRECT",
      },
    ],
  },
  {
    word: "INCORRECT ANSWERS",
    languages: [
      {
        language: GameLanguages.ENUK,
        translation: "INCORRECT ANSWERS",
      },
      {
        language: GameLanguages.DUTCH,
        translation: "INCORRECT ANSWERS",
      },
    ],
  },
  {
    word: "HINTS USED",
    languages: [
      {
        language: GameLanguages.ENUK,
        translation: "HINTS USED",
      },
      {
        language: GameLanguages.DUTCH,
        translation: "HINTS  GEBRUIKT",
      },
    ],
  },
  {
    word: "HINT PENALTY",
    languages: [
      {
        language: GameLanguages.ENUK,
        translation: "HINT PENALTY",
      },
      {
        language: GameLanguages.DUTCH,
        translation: "HINT PENALTY",
      },
    ],
  },
  {
    word: "NEXT HINT AVAILABLE",
    languages: [
      {
        language: GameLanguages.ENUK,
        translation: "NEXT HINT AVAILABLE",
      },
      {
        language: GameLanguages.DUTCH,
        translation: "VOLGENDE HINT",
      },
    ],
  },
  {
    word: "INCORRECT ANSWER PENALTY",
    languages: [
      {
        language: GameLanguages.ENUK,
        translation: "INCORRECT ANSWER PENALTY",
      },
      {
        language: GameLanguages.DUTCH,
        translation: "INCORRECT ANTWOORD",
      },
    ],
  },
  {
    word: "SUBMIT ANSWER",
    languages: [
      {
        language: GameLanguages.ENUK,
        translation: "SUBMIT ANSWER",
      },
      {
        language: GameLanguages.DUTCH,
        translation: "VERSTUUR ANTWOORD",
      },
    ],
  },
  {
    word: "REQUEST HINT",
    languages: [
      {
        language: GameLanguages.ENUK,
        translation: "REQUEST HINT",
      },
      {
        language: GameLanguages.DUTCH,
        translation: "HINT",
      },
    ],
  },
  {
    word: "NOW",
    languages: [
      {
        language: GameLanguages.ENUK,
        translation: "NOW",
      },
      {
        language: GameLanguages.DUTCH,
        translation: "BESCHIKBAAR",
      },
    ],
  },
  {
    word: "CLOSE",
    languages: [
      {
        language: GameLanguages.ENUK,
        translation: "CLOSE",
      },
      {
        language: GameLanguages.DUTCH,
        translation: "SLUITEN",
      },
    ],
  },
  {
    word: "CONTINUE",
    languages: [
      {
        language: GameLanguages.ENUK,
        translation: "CONTINUE",
      },
      {
        language: GameLanguages.DUTCH,
        translation: "GA DOOR",
      },
    ],
  },
  {
    word: "Rank",
    languages: [
      {
        language: GameLanguages.ENUK,
        translation: "Rank",
      },
      {
        language: GameLanguages.DUTCH,
        translation: "Rang",
      },
    ],
  },
  {
    word: "Team Name",
    languages: [
      {
        language: GameLanguages.ENUK,
        translation: "Team Name",
      },
      {
        language: GameLanguages.DUTCH,
        translation: "Teamnaam",
      },
    ],
  },
  {
    word: "Incorrect",
    languages: [
      {
        language: GameLanguages.ENUK,
        translation: "Incorrect",
      },
      {
        language: GameLanguages.DUTCH,
        translation: "Onjuist",
      },
    ],
  },
  {
    word: "Result",
    languages: [
      {
        language: GameLanguages.ENUK,
        translation: "Result",
      },
      {
        language: GameLanguages.DUTCH,
        translation: "Resultaat",
      },
    ],
  },
  {
    word: "Evidence_1.txt",
    languages: [
      {
        language: GameLanguages.ENUK,
        translation: "Evidence_1.txt",
      },
      {
        language: GameLanguages.DUTCH,
        translation: "Bewijs1.txt",
      },
    ],
  },
  {
    word: "Evidence_2.png",
    languages: [
      {
        language: GameLanguages.ENUK,
        translation: "Evidence_2.png",
      },
      {
        language: GameLanguages.DUTCH,
        translation: "Bewijs2.png",
      },
    ],
  },
  {
    word: "Experios presents",
    languages: [
      {
        language: GameLanguages.ENUK,
        translation: "Experios presents",
      },
      {
        language: GameLanguages.DUTCH,
        translation: "Experios presenteert",
      },
    ],
  },
  {
    word: "THE CHALLENGE",
    languages: [
      {
        language: GameLanguages.ENUK,
        translation: "THE CHALLENGE",
      },
      {
        language: GameLanguages.DUTCH,
        translation: "HET RAADSEL",
      },
    ],
  },
  {
    word: "VISIT OUR WEBSITE",
    languages: [
      {
        language: GameLanguages.ENUK,
        translation: "VISIT OUR WEBSITE",
      },
      {
        language: GameLanguages.DUTCH,
        translation: "NAAR ONZE WEBSITE",
      },
    ],
  },
  {
    word: "(click to enlarge)",
    languages: [
      {
        language: GameLanguages.ENUK,
        translation: "(click to enlarge)",
      },
      {
        language: GameLanguages.DUTCH,
        translation: "(klik om te vergroten)",
      },
    ],
  },
  {
    word: "ANSWER",
    languages: [
      {
        language: GameLanguages.ENUK,
        translation: "ANSWER",
      },
      {
        language: GameLanguages.DUTCH,
        translation: "ANTWOORD",
      },
    ],
  },
];
