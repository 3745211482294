import classes from "./ingridientsPanel.module.scss";
import React, { Fragment, useState, useContext, useEffect } from "react";
import Rum from "../../../assets/BlackeyeTreasure/Step2_rum143.png";
import Cinnamon from "../../../assets/BlackeyeTreasure/Step2_cinnamon143.png";
import Water from "../../../assets/BlackeyeTreasure/Step2_water143.png";
import Sugar from "../../../assets/BlackeyeTreasure/Step2_sugar143.png";
import InputField from "../../inputfield/inputfield";
import Button from "../../button/button";
import { BTGameContext } from "../../../context/BTGameContext/BTGameContext";
import perfect from "../../../assets/BlackeyeTreasure/perfect.mp4";
import rumLess from "../../../assets/BlackeyeTreasure/rum_less.mp4";
import rumMore from "../../../assets/BlackeyeTreasure/rum_more.mp4";
import cinnamonLess from "../../../assets/BlackeyeTreasure/cinnamon_less.mp4";
import cinnamonMore from "../../../assets/BlackeyeTreasure/cinnamon_more.mp4";
import waterLess from "../../../assets/BlackeyeTreasure/water_less.mp4";
import waterMore from "../../../assets/BlackeyeTreasure/water_more.mp4";
import sugarLess from "../../../assets/BlackeyeTreasure/sugar_less.mp4";
import sugarMore from "../../../assets/BlackeyeTreasure/sugar_more.mp4";

const ingredientsCorrectAmount = ["140", "50", "2", "3"];
const ingredientsVideos = [
  {
    less: rumLess,
    more: rumMore,
  },
  {
    less: waterLess,
    more: waterMore,
  },
  {
    less: cinnamonLess,
    more: cinnamonMore,
  },
  {
    less: sugarLess,
    more: sugarMore,
  },
];

const IngredientsPanel: React.FC = () => {
  const [rum, setRum] = useState("");
  const [water, setWater] = useState("");
  const [cinnamon, setCinnamon] = useState("");
  const [sugar, setSugar] = useState("");
  const {
    updateIngredientsAmount,
    updateIngredientVideo,
    updatePlayAgainIngredientVideo,
  } = useContext(BTGameContext);


  useEffect(() => {

    return (() => {
      updateIngredientsAmount([]);
      updateIngredientVideo("")
    })
  }, [])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const numericValue = value.replace(/[^0-9]/g, "");
    if (name === "rum") {
      setRum(numericValue);
    } else if (name === "water") {
      setWater(numericValue);
    } else if (name === "cinnamon") {
      setCinnamon(numericValue);
    } else if (name === "sugar") {
      setSugar(numericValue);
    }
    updateIngredientsAmount([]);
  };

  // const resetValues = () => {
  //   setRum("");
  //   setWater("");
  //   setCinnamon("");
  //   setSugar("");
  // };

  const onServeClicked = () => {
    updatePlayAgainIngredientVideo(Date.now());
    const newIngredientsQuantity = [rum, water, cinnamon, sugar];
    let allCorrectIngredients = true;
    for (let i = 0; i < newIngredientsQuantity.length; i++) {
      const ingredientValue = parseInt(newIngredientsQuantity[i]);
      const correctAmount = parseInt(ingredientsCorrectAmount[i]);
      if (ingredientValue < correctAmount) {
        allCorrectIngredients = false;
        updateIngredientVideo(ingredientsVideos[i].less);
        break;
      } else if (ingredientValue > correctAmount) {
        allCorrectIngredients = false;
        updateIngredientVideo(ingredientsVideos[i].more);
        break;
      }
    }
    if (allCorrectIngredients) {
      updateIngredientVideo(perfect);
    }
    const newIngredientsQuantity2 = [`${rum},${water},${cinnamon},${sugar}`];
    updateIngredientsAmount(newIngredientsQuantity2);
  };

  const isServeButtonDisabled = !rum || !water || !cinnamon || !sugar;
  return (
    <Fragment>
      <div className={classes["content-container"]}>
        <div className={classes["picture-column"]}>
          <img src={Rum} alt="rum" />
          <InputField
            type="text"
            name="rum"
            style={{ width: "100px", textAlign: "center", paddingLeft: "0px" }}
            value={rum}
            onValueChanged={handleInputChange}
            maxLength={3}
          />

          <span>ml rum</span>
        </div>
        <div className={classes["picture-column"]}>
          <img src={Water} alt="water" />
          <InputField
            style={{ width: "100px", textAlign: "center", paddingLeft: "0px" }}
            type="text"
            name="water"
            value={water}
            onValueChanged={handleInputChange}
            maxLength={3}
          />
          <span>ml water</span>
        </div>
        <div className={classes["picture-column"]}>
          <img src={Cinnamon} alt="cinnamon" />
          <InputField
            type="text"
            name="cinnamon"
            style={{ width: "100px", textAlign: "center", paddingLeft: "0px" }}
            value={cinnamon}
            onValueChanged={handleInputChange}
            maxLength={3}
          />
          <span>tsp. cinnamon</span>
        </div>
        <div className={classes["picture-column"]}>
          <img src={Sugar} alt="sugar" />
          <InputField
            type="text"
            name="sugar"
            style={{ width: "100px", textAlign: "center", paddingLeft: "0px" }}
            value={sugar}
            onValueChanged={handleInputChange}
            maxLength={3}
          />
          <span>tsp. sugar</span>
        </div>
      </div>

      <div className={`${classes["serve-button-container"]}`}>
        <Button
          text="SERVE"
          style={{
            fontSize: "0.8em",
            alignSelf: "center",
            width: "200px",
            height: "50px",
          }}
          onClick={onServeClicked}
          disabled={isServeButtonDisabled}
        ></Button>
      </div>
    </Fragment>
  );
};

export default IngredientsPanel;
