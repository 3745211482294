import React, { Fragment, useContext } from "react";
import { GamestateContext } from "../../../context/GameState/GameStateContext";
import { getTranslatedWord } from "../../../metadata/ButtonText";
import classes from "./imagepanel.module.scss";
import ZoomImagePanel from "./zoomable-image";
import { CSSProperties } from "@material-ui/core/styles/withStyles";

interface IImagePanel extends React.ImgHTMLAttributes<HTMLImageElement> {
  viewerImage: any;
  footerText?: string;
  defaultScale?: number;
  leftIconClass?: string;
  rightIconClass?: string;
  iconStyles?: CSSProperties;
}
const SlideShow: React.FC<IImagePanel> = ({
  viewerImage,
  footerText = undefined,
  defaultScale = 0.75,
  leftIconClass,
  rightIconClass,
  iconStyles,
  ...rest
}) => {
  const { gameTime, gameLanguage } = useContext(GamestateContext);
  const [isZoomedOn, showZoomed] = React.useState(false);
  const [currentIndex, setCurrentIndex] = React.useState(0);

  const onLeftButtonClick = () => {
    if (currentIndex === 0) {
      setCurrentIndex(viewerImage.length - 1);
    } else {
      setCurrentIndex(currentIndex - 1);
    }
  };
  const onRightButtonClick = () => {
    if (currentIndex === viewerImage.length - 1) {
      setCurrentIndex(0);
    } else {
      setCurrentIndex(currentIndex + 1);
    }
  };
  return (
    <Fragment>
      {isZoomedOn ? (
        <ZoomImagePanel
          image={viewerImage[currentIndex].url}
          defaultScale={defaultScale}
          onClosePress={() => {
            showZoomed(false);
          }}
        ></ZoomImagePanel>
      ) : null}

      <div className={classes["content-container"]} style={rest.style}>
        <i
          className={`${
            leftIconClass ? leftIconClass : "fas fa-arrow-circle-left ml-4"
          } ml-4`}
          style={{
            fontSize: "40px",
            position: "absolute",
            cursor: "pointer",
            top: "45%",
            left: "0%",
            ...iconStyles,
          }}
          onClick={onLeftButtonClick}
        ></i>
        <i
          // className="fas fa-arrow-circle-right mr-4"
          className={`${
            rightIconClass ? rightIconClass : "fas fa-arrow-circle-right mr-4"
          } `}
          style={{
            fontSize: "40px",
            position: "absolute",
            cursor: "pointer",
            top: "45%",
            right: "0%",
            ...iconStyles,
          }}
          onClick={onRightButtonClick}
        ></i>
        <img
          src={viewerImage[currentIndex].url}
          alt=""
          style={{ minWidth: "25%", maxWidth: "100%", cursor: "pointer" }}
          onClick={() => {
            showZoomed(true);
          }}
        ></img>

        <div className={classes["content-container-text"]}>
          <span>
            {footerText === undefined
              ? getTranslatedWord("(click to enlarge)", gameLanguage)
              : footerText}
          </span>
        </div>
      </div>
    </Fragment>
  );
};

//export default ImagePanel;

export default React.memo(SlideShow, function arePropsAreEqual(prev, next) {
  return (
    prev.footerText === next.footerText && prev.viewerImage === next.viewerImage
  );
});
