import classes from "./videoPlayer.module.scss";
import poster from "../../../assets/BlackeyeTreasure/Step2.jpg";
import React, { useRef, useEffect } from "react";

interface IVideoPlayer{
  videoUrl:string
}

const VideoPlayer:React.FC<IVideoPlayer> = ({ videoUrl, ...rest }: any) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    const playVideo = async () => {
      try {
        if (videoRef.current) {
          videoRef.current.pause();
          videoRef.current.src = videoUrl;
          videoRef.current.load();
          await videoRef.current.play();
        }
      } catch (error) {
        // To DO 
      }
    };

    playVideo();
  }, [videoUrl]);

  return (
    <div className={classes["content-container"]} style={rest.style}>
      <video
        ref={videoRef}
        // controls
        poster={poster}
        style={{ objectFit: "cover", height: "auto", maxWidth: "100%", maxHeight: "416px", width: "100%", }}
      >
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default VideoPlayer;
