import React, { Fragment, ReactNode } from "react";
import {
  EQuestionType,
  GameTypes,
  GameLanguages,
} from "../container/Enums/Enums";
import Step3 from "../assets/TTGame/Step3.jpg";
import Step4 from "../assets/TTGame/Step4.jpg";
import Step6 from "../assets/TTGame/Step6.png";
import Step7 from "../assets/TTGame/Step7.jpg";
import Step9 from "../assets/TTGame/timeroff.png";
import Step10 from "../assets/TTGame/Step10.jpg";
import Step11 from "../assets/TTGame/Step11.jpg";
import Step12 from "../assets/TTGame/Step12.jpg";
import EvidenceImage from "../assets/TTGame/evidence.jpg";
import TextFileSVG from "../assets/TTGame/textFile.svg";
import EvidencSVG from "../assets/TTGame/evidence.svg";
import FooterImage from "../components/game/Step1/FooterImages";
import ImageViewer from "../components/game/imagepanel/imagepanel";
import Recording from "../components/game/recordings/recordingPanel";
// import ModelViewer from "../components/modelviewer/modelviewer";

import TTIntroImage from "../assets/BG/IntroBG.jpg";
import TTGameImage from "../assets/BG/GameSteps.jpg";
import TTResultImage from "../assets/BG/Result.jpg";
import TTLeaderboardImage from "../assets/BG/Leaderboard.jpg";

import BBHIntroImage from "../assets/BBHGame/BG/GameIntro.jpg";
import BBHGameImage from "../assets/BBHGame/BG/Question.jpg";
import BBHResultFailImage from "../assets/BBHGame/BG/ResultFail.jpg";
import BBHResultPassImage from "../assets/BBHGame/BG/ResultPass.jpg";
import BBHLeaderboardImage from "../assets/BBHGame/BG/Leaderboard.jpg";

import JHIntroImage from "../assets/HangoverGame/BG/rules.jpg";
import JHGameImage from "../assets/HangoverGame/BG/step.jpg";
import JHResultFailImage from "../assets/HangoverGame/BG/fail.jpg";
import JHResultPassImage from "../assets/HangoverGame/BG/success.jpg";
import JHLeaderboardImage from "../assets/HangoverGame/BG/leaderboard.jpg";

import MayDayIntroImage from "../assets/MayDay/BG/IntroBG.jpg";
import MayDayGameImage from "../assets/MayDay/BG/GameSteps.jpg";
import MayDayLeaderboardImage from "../assets/MayDay/BG/Leaderboard.jpg";
import MayDayResultPassImage from "../assets/MayDay/BG/Result.jpg";

// import BTIntroImage from "../assets/BlackeyeTreasure/BG/GameIntro.jpg";
// import BTGameImage from "../assets/BlackeyeTreasure/BG/GameSteps.jpg";
// import BTLeaderboardImage from "../assets/BlackeyeTreasure/BG/Leaderboard.jpg";
// import BTResultImage from "../assets/BlackeyeTreasure/BG/Success.jpg";

import * as TTJrData from "./TTJrMeta";
import * as BBHData from "./BBHMeta";
import * as JHData from "./JackHangover";
import * as MayDayData from "./MayDayMeta";
import * as BTData from "./BlackeyeTreasureMeta";
import { IGameState } from "../context/GameState/GameStateInterface";
import { isBBHCompleted } from "../container/GameMenu/Menu";
import Step1FooterMap from "../components/tt/Step1Map";
import LinkMap from "../components/tt/LinkMap";
import { BTBackgroundFolder } from "../constants";

export interface IQuestion {
  Id: string;
  Type: EQuestionType;
  Question: string;
  CustomInputType?: ReactNode;
  Hints?: string[];
  Options: string[];
  DropDownsOptions?:string[][];
  InputPattern?: string;
  InputMaxLimit?: number;
  Body: ReactNode[];
  Content: string[];
}

export const getGameBackground = (type: string): string[] => {
  switch (type) {
    case GameTypes.BBH:
      return [
        BBHIntroImage,
        BBHResultFailImage,
        BBHResultPassImage,
        BBHGameImage,
        BBHLeaderboardImage,
      ];
    case GameTypes.JH:
      return [
        JHIntroImage,
        JHResultFailImage,
        JHResultPassImage,
        JHGameImage,
        JHLeaderboardImage,
      ];
    case GameTypes.MAYDAY:
      return [
        MayDayGameImage,
        MayDayIntroImage,
        MayDayLeaderboardImage,
        MayDayResultPassImage,
      ];
    case GameTypes.BT:
      return [
        BTBackgroundFolder + "MainMenu.jpg",
        BTBackgroundFolder + "GameIntro.jpg",
        BTBackgroundFolder + "Leaderboard.jpg",
        BTBackgroundFolder + "Success.jpg",
        BTBackgroundFolder + "GameSteps.jpg",
      ];
    default:
      return [TTIntroImage, TTResultImage, TTGameImage, TTLeaderboardImage];
  }
};
export const getGameBGByScreen = (screen: string, type: string): string => {
  switch (type) {
    case GameTypes.BBH:
      return screen + "_bbh";
    case GameTypes.JH:
      return screen + "_jh";
    case GameTypes.MAYDAY:
      return screen + "_mayday";
    case GameTypes.BT:
      return screen + "_bt";
    default:
      return screen + "_tt";
  }
};
export const getGameQuestions = (
  type: string,
  language: string
): IQuestion[] => {
  switch (type) {
    case GameTypes.TTJr:
      return TTJrData.getGameQuestionsByLanguage(language);
    case GameTypes.BBH:
      return BBHData.getGameQuestionsByLanguage(language);
    case GameTypes.JH:
      return JHData.getGameQuestionsByLanguage(language);
    case GameTypes.MAYDAY:
      return MayDayData.getGameQuestionsByLanguage(language);
    case GameTypes.BT:
      return BTData.getGameQuestionsByLanguage(language);
    default:
      return getGameQuestionsByLanguage(language);
  }
};

export const getNextQuestionId = (
  type: string,
  language: string,
  currentStep: string
): string => {
  let questions = getGameQuestions(type, language);
  let index = questions.findIndex((value) => {
    return value.Id === currentStep;
  });
  if (index + 1 < questions.length) {
    return questions[index + 1].Id;
  }
  return currentStep;
};
export const getGameQuestionById = (
  gameState: IGameState,
  type: string,
  language: string
): IQuestion => {
  const Id =
    type === GameTypes.BBH && isBBHCompleted(gameState)
      ? gameState.gameStepNo
      : gameState.gameStepNo;

  return getQuestionById(Id, type, language);
};

const getQuestionById = (
  Id: string,
  type: string,
  language: string
): IQuestion => {
  const questions = getGameQuestions(type, language);
  const question = questions.find((x) => x.Id === Id);
  return question === undefined ? defaultQuestion : question;
};

const getGameQuestionsByLanguage = (language?: string): IQuestion[] => {
  switch (language) {
    case GameLanguages.ENUK:
      return Questions;
    case GameLanguages.DUTCH:
      return QuestionsNL;
    default:
      return Questions;
  }
};
// const getQuestionByLanguage = (language: string): IQuestion => {
//   return defaultQuestion;
//   switch (language) {
//     case GameLanguages.ENUK:
//       break;
//     case GameLanguages.DUTCH:
//       break;
//     default:
//       return defaultQuestion;
//   }
// };

const defaultQuestion: IQuestion = {
  Id: "1",
  Type: EQuestionType.Dropdown,
  Question:
    "Which website is likely to contain more information about the bomb?",
  Hints: ["", "", ""],
  Options: ["", "St Stephen's Anglican Church", "City Discovery", "Google"],
  Body: [
    <span key={1} style={{ color: "#7ED321", fontSize: "0.8em" }}>
      ------
    </span>,
    <Recording
      key={2}
      header={"AUDIO RECORDING"}
      path={"assests/GameFiles/recording.mp3"}
    ></Recording>,
    <Fragment key={121}>
      <span key={1} style={{ color: "#7ED321", fontSize: "0.8em" }}>
        --------
      </span>
      <span key={2} className={"mt-2"}>
        Our tech team managed to secure some evidence from his phone before he
        switched it off.
      </span>
    </Fragment>,
  ],
  Content: [Step3],
};
const Questions: IQuestion[] = [
  {
    Id: "1",
    Type: EQuestionType.Dropdown,
    Question:
      "Which website is likely to contain more information about the bomb?",
    Hints: ["", "", ""],
    Options: [
      "",
      "St Stephen's Anglican Church",
      "City Discovery",
      "Google",
      "The Engineer",
      "Wikipedia",
      "Mind Games",
      "Steve the Bartender",
      "Youtube",
      "Bjorn's website",
      "Telstra",
    ],
    Body: [
      <span key={1} style={{ color: "#7ED321", fontSize: "0.8em" }}>
        INTRO
      </span>,
      <span key={2} className={"mt-2"}>
        All right, we’ve just received a phone call from a terrorist threatening
        the blow up a building in the city in the next 60 minutes.
      </span>,
      <span key={3} className={"mt-3"}>
        We need to find the bomb and disarm it.
      </span>,
      <span key={4}>
        We then need to catch the terrorist. Let’s work together and do this.
      </span>,
      <span key={5} className={"mt-3"}>
        Here is the audio recording with the terrorist.
      </span>,
      <Recording
        key={6}
        header={"AUDIO RECORDING"}
        path={"assests/GameFiles/recording.mp3"}
      ></Recording>,
      <span key={7} style={{ color: "#7ED321", fontSize: "0.8em" }}>
        EVIDENCE
      </span>,
      <span key={8} className={"mt-2"}>
        Our tech team managed to secure some evidence from his phone before he
        switched it off.
      </span>,
      <span key={13}>
        We’ve also identified he’s somewhere in the east of Melbourne,
        Australia. But the call was too short for an exact location.
      </span>,
      <span className={"mt-3"} key={41}>
        Before we look for him, let’s first find the bomb and disarm it.
      </span>,
      <span key={15}>
        Hopefully one of the websites contains more information about its
        location and how to disarm it.
      </span>,
      <span className={"mt-3"} key={16}>
        Can you have a look at the websites and inform me once you find a
        suspicious looking website that could contain more information?
      </span>,
      <Step1FooterMap
        key={17}
        paths={[
          `${process.env.PUBLIC_URL}/assests/GameFiles/TT/evidence1.txt`,
          EvidenceImage,
        ]}
        images={[TextFileSVG, EvidencSVG]}
        itemNames={["Evidence_1.txt", "Evidence_2.png"]}
        footerText={"(click to enlarge)"}
      ></Step1FooterMap>,
    ],

    Content: [Step3],
  },
  {
    Id: "2",
    Type: EQuestionType.Input,
    Question:
      "That Bjorn Willy website looks very suspicious indeed. But it needs a password to log in. Can you tell us what the password is?",
    Hints: ["", "", ""],
    Options: [""],
    Body: [
      <span style={{ color: "#7ED321", fontSize: "0.8em" }} key={1}>
        INTRO
      </span>,
      <span className={"mt-2"} key={2}>
        All right, we’ve just received a phone call from a terrorist threatening
        the blow up a building in the city in the next 60 minutes.
      </span>,
      <span key={3} className={"mt-3"}>
        We need to find the bomb and disarm it.
      </span>,
      <span key={4}>
        We then need to catch the terrorist. Let’s work together and do this.
      </span>,
      <span key={5} className={"mt-3"}>
        Here is the audio recording with the terrorist.
      </span>,
      <Recording
        key={6}
        header={"AUDIO RECORDING"}
        path={"assests/GameFiles/recording.mp3"}
      ></Recording>,
      <Fragment key={121}>
        <span key={1} style={{ color: "#7ED321", fontSize: "0.8em" }}>
          EVIDENCE
        </span>
        <span key={2} className={"mt-2"}>
          Our tech team managed to secure some evidence from his phone before he
          switched it off.
        </span>
        <span key={3}>
          We’ve also identified he’s somewhere in the east of Melbourne,
          Australia. But the call was too short for an exact location.
        </span>
        <span key={4} className={"mt-3"}>
          Before we look for him, let’s first find the bomb and disarm it.
        </span>
        <span key={5}>
          Hopefully one of the websites contains more information about its
          location and how to disarm it.
        </span>
        <Step1FooterMap
          key={7}
          paths={[
            `${process.env.PUBLIC_URL}/assests/GameFiles/TT/evidence1.txt`,
            EvidenceImage,
          ]}
          images={[TextFileSVG, EvidencSVG]}
          itemNames={["Evidence_1.txt", "Evidence_2.png"]}
          footerText={"(click to enlarge)"}
        ></Step1FooterMap>
      </Fragment>,
    ],
    Content: [Step3],
  },
  {
    Id: "3",
    Type: EQuestionType.Input,
    Question:
      "Can you tell us the name of the location where the squad can find the bomb?",
    Hints: ["", "", ""],
    Options: [""],
    Body: [
      <span key={1} style={{ color: "#7ED321", fontSize: "0.8em" }}>
        WEBSITE
      </span>,
      <span key={2} className={"mt-2"}>
        Awesome work!
      </span>,
      <span key={3}>
        Now that we are on his{" "}
        <a
          href={"https://bjornwilly.com/"}
          target="_blank"
          rel="noreferrer"
          style={{ color: "red" }}
        >
          <u>website</u>
        </a>
        , I'd suggest you split up and look at the contents.
      </span>,
      <ImageViewer
        key={4}
        linkImage={"https://bjornwilly.com/"}
        viewerImage={Step3}
        footerText={"(Click to open website)"}
      ></ImageViewer>,
      <Fragment key={121}>
        <span key={1} className={"mt-2"}>
          We need to know the location of the bomb and hopefully there's also
          some information about the bomb itself.
        </span>
        <span key={2}>
          Let me know once you figured out what the location is and I'll send
          the squad there.
        </span>
      </Fragment>,
    ],
    Content: [Step3],
  },
  {
    Id: "4",
    Type: EQuestionType.Input,
    Question:
      "What is the name of the document that contains more information about how to disarm the bomb?",
    Hints: ["", "", ""],
    Options: [""],
    Body: [
      <span key={1} style={{ color: "#7ED321", fontSize: "0.8em" }}>
        SUITCASE
      </span>,
      <span key={3} className={"mt-2"}>
        I'll send a squad to the location right away.
      </span>,
      <span key={2}>
        They are around the corner and should be there in a matter of seconds...
      </span>,
      // <ModelViewer key={4} path={"TT/SuitCase/suitcasev1.glb"}></ModelViewer>,
      <ImageViewer
        key={4}
        linkImage={Step4}
        viewerImage={Step4}
        defaultScale={0.9}
      ></ImageViewer>,
      <Fragment key={121}>
        <span key={1} className={"mt-2"}>
          Ok, they have arrived and found a suitcase.
        </span>
        <span key={2} className={"mt-2"}>
          Now we hope one of the documents contains more information on how to
          disarm the bomb.
        </span>
        <span key={3}>Let me know once you've found it! </span>
      </Fragment>,
    ],
    Content: [Step4],
  },
  {
    Id: "5",
    Type: EQuestionType.Input,
    Question: "What is the password to open the document? (case sensitive)",
    Hints: ["", "", ""],
    Options: [""],
    Body: [
      <span key={1} style={{ color: "#7ED321", fontSize: "0.8em" }}>
        SUITCASE
      </span>,
      <span key={2} className={"mt-2"}>
        Ok, now that we know which document contains more information, we need
        to find the password to open it...
      </span>,
      // <ModelViewer key={4} path={"TT/SuitCase/suitcasev1.glb"}></ModelViewer>,
      <ImageViewer
        key={4}
        linkImage={Step4}
        viewerImage={Step4}
        defaultScale={0.9}
      ></ImageViewer>,
      <Fragment key={121}>
        <span key={1} className={"mt-2"}>
          Perhaps one of his emails contains more information.
          <br></br>
          Have a quick look at his emails and try to figure out the password to
          open the document.
        </span>
      </Fragment>,
    ],
    Content: [Step4],
  },
  {
    Id: "6",
    Type: EQuestionType.Input,
    Question: "What is step 1 of disarming the bomb?",
    Hints: ["", "", ""],
    Options: [""],
    Body: [
      <span key={1} style={{ color: "#7ED321", fontSize: "0.8em" }}>
        DOCUMENT
      </span>,
      <span key={2} className={"mt-2"}>
        Great work team!
      </span>,
      <span key={3} className={"mt-2"}>
        The{" "}
        <a
          href={`${process.env.PUBLIC_URL}/assests/GameFiles/TT/madheaven.pdf`}
          target="_blank"
          rel="noreferrer"
          style={{ color: "red" }}
        >
          <u>document</u>
        </a>{" "}
        is open and the password was "libreville".
      </span>,
      <ImageViewer
        key={4}
        linkImage={`${process.env.PUBLIC_URL}/assests/GameFiles/TT/madheaven.pdf`}
        viewerImage={Step6}
        footerText={"(Click to open document)"}
      ></ImageViewer>,
      <Fragment key={121}>
        <span key={11} className={"mt-3"}>
          However, it seems disarming this bomb requires some expertise.
        </span>
        <span key={1}>
          Luckily you are the experts and I'm sure you're able to figure this
          out.
        </span>
        <span key={2} className={"mt-3"}>
          If you can go through the manual and let me know which steps to take
          to disarm the bomb, then I'll inform the squad.
        </span>
      </Fragment>,
    ],

    Content: [Step6],
  },
  {
    Id: "7",
    Type: EQuestionType.CheckBox,
    Question: "Which 3 wires do the squad need to cut?",
    Hints: ["", "", ""],
    Options: ["W14", "W19", "W21", "W23", "W43", "W59", "W64", "W71", "W81"],
    Body: [
      <span key={1} style={{ color: "#7ED321", fontSize: "0.8em" }}>
        WIRES
      </span>,
      <span key={2} className={"mt-2"}>
        Great, the suitcase is open.
        <br></br>
        Time to start following the disarming steps.
      </span>,
      <ImageViewer
        key={4}
        linkImage={Step9}
        viewerImage={Step9}
        isDynamic={true}
      ></ImageViewer>,
      <Fragment key={121}>
        <span key={2} className={"mt-2"}>
          There's lots of wires and the squad isn't sure which ones they need to
          cut.
        </span>
        <span className={"mt-2"} key={4}>
          But they are sure you've got the skills to find out the correct wires
          to cut.
          <br></br>
          Have a close look at the bomb and the steps in the bomb manual.
        </span>
      </Fragment>,
      // <ModelViewer
      //   key={4}
      //   path={"TT/Bomb/timerbomb.glb"}
      //   zoomValue={2.5}
      // ></ModelViewer>,
    ],
    Content: [Step7],
  },
  {
    Id: "8",
    Type: EQuestionType.Input,
    Question: "What is the 4 digit confirmation code to disarm the bomb?",
    Hints: ["", "", ""],
    Options: [""],
    InputPattern: "^[0-9]*$",
    InputMaxLimit: 4,
    Body: [
      <span key={1} style={{ color: "#7ED321", fontSize: "0.8em" }}>
        CONFIRMATION CODE
      </span>,
      <span key={2} className={"mt-2"}>
        So far so good, that's probably all the wires.
      </span>,
      <ImageViewer
        key={4}
        linkImage={Step7}
        viewerImage={Step7}
        isDynamic={true}
      ></ImageViewer>,
      <span key={12} className={"mt-2"}>
        The next step is to find the confirmation code. For this model that
        seems to be a 4 digit code.
      </span>,
      // <ModelViewer
      //   key={4}
      //   path={"TT/Bomb/timerbomb.glb"}
      //   zoomValue={2.5}
      // ></ModelViewer>,
    ],
    Content: [Step7],
  },
  {
    Id: "9",
    Type: EQuestionType.Input,
    Question: "What is the last known location of the suspect?",
    Hints: ["", "", ""],
    Options: [""],
    Body: [
      <span key={1} style={{ color: "#7ED321", fontSize: "0.8em" }}>
        LAST KNOWN LOCATION
      </span>,
      <span key={2} className={"mt-2"}>
        Yes! That worked, the bomb is now disarmed.
      </span>,
      <span key={3} className={"mt-2"}>
        Thanks team, great work. You might want to consider a career at the
        Melbourne Police Department...
      </span>,
      <ImageViewer key={4} linkImage={Step9} viewerImage={Step9}></ImageViewer>,
      <Fragment key={121}>
        <span key={2} className={"mt-2"}>
          Now let's find the person responsible.
        </span>

        <span className={"mt-2"} key={4}>
          Have a look at his website and see if you can find anything related to
          his last known location.
        </span>

        <span key={3}>
          Also make sure to have another look at that{" "}
          <span style={{ display: "inline-flex", alignItems: "center" }}>
            <LinkMap linkText="evidence" image={EvidenceImage} />
          </span>{" "}
          we retrieved from the suspect's phone.
        </span>
      </Fragment>,
    ],
    Content: [Step9],
  },
  {
    Id: "10",
    Type: EQuestionType.Input,
    Question: "What is the address of the suspect?",
    Hints: ["", "", ""],
    Options: [""],
    Body: [
      <span key={1} style={{ color: "#7ED321", fontSize: "0.8em" }}>
        LOCATION
      </span>,
      <span key={2} className={"mt-2"}>
        A squad is on its way to see if anyone at the church knows about him and
        where he could be...
      </span>,
      <ImageViewer
        key={4}
        linkImage={Step10}
        viewerImage={Step10}
      ></ImageViewer>,
      <Fragment key={121}>
        <span key={2} className={"mt-2"}>
          We're getting close now!
        </span>
        <span className={"mt-2"} key={4}>
          Someone at the church didn't know the exact address but gave us
          instructions on how to get to his place.
        </span>
        <span className={"mt-3"} key={6}>
          Here are the instructions we received:<br></br>
          1. Start at the church at 360 Church Street in Richmond VIC<br></br>
          2. Go north until you reach the park of the people
          <br></br>
          3. Go right and the 2nd left<br></br>
          4. Go right until you get to a roundabout<br></br>
          5. Take the 1st exit and keep going straight until the fork
          <br></br>
          6. Follow the road to the left at the fork<br></br>
          7. Now you might have to take a good look...it's one of the houses on
          the right, with red bricks...at the beginning of the street<br></br>
        </span>
      </Fragment>,
    ],
    Content: [Step10],
  },
  {
    Id: "11",
    Type: EQuestionType.RadioButton,
    Question: "Should the squad kick the door in or ask him to come out?",
    Hints: ["", "", ""],
    Options: ["Kick the door in", "Ask him to come out"],
    Body: [
      <span key={1} style={{ color: "#7ED321", fontSize: "0.8em" }}>
        AT THE HOUSE
      </span>,
      <span key={2} className={"mt-3"}>
        The squad has arrived at the house.
      </span>,
      <ImageViewer
        key={4}
        linkImage={Step11}
        viewerImage={Step11}
      ></ImageViewer>,
      <span key={12} className={"mt-2"}>
        Time to make a quick decision.
      </span>,
      <span key={14}>
        They can either kick the door in or ask him to come out.
      </span>,
      <span className={"mt-3"} key={16}>
        But we need to hurry up!
      </span>,
    ],
    Content: [Step11],
  },
  {
    Id: "12A",
    Type: EQuestionType.Input,
    Question: "What is the code to open the door?",
    Hints: ["", "", ""],
    Options: [],
    InputPattern: "^[0-9]*$",
    InputMaxLimit: 4,
    Body: [
      <span key={1} style={{ color: "#7ED321", fontSize: "0.8em" }}>
        INSIDE
      </span>,
      <span key={2} className={"mt-3"}>
        The squad kicked the door in and is now inside, but he has locked
        himself up in a room and the door to the room has a keypad. We need to
        hurry and get into that room before it's too late!
      </span>,
      <ImageViewer
        key={4}
        linkImage={Step12}
        viewerImage={Step12}
      ></ImageViewer>,
      <span key={13} className={"mt-3"}>
        A squad member noticed a colourful clock that stopped ticking. It seems
        a bit suspicious, perhaps it's a clue to the code of the door? Can you
        give us the code?
      </span>,
    ],
    Content: [Step12],
  },
  {
    Id: "12B",
    Type: EQuestionType.Input,
    Question: "What is the code to open the door?",
    Hints: ["", "", ""],
    Options: [],
    InputPattern: "^[0-9]*$",
    InputMaxLimit: 4,
    Body: [
      <span key={1} style={{ color: "#7ED321", fontSize: "0.8em" }}>
        INSIDE
      </span>,
      <span key={2} className={"mt-3"}>
        The squad ask him to come out, but he's shouting back that he's refusing
        to come outside.
      </span>,
      <span key={3}>
        Luckily the squad found a way in via the back entrance.
      </span>,
      <ImageViewer
        key={4}
        linkImage={Step12}
        viewerImage={Step12}
      ></ImageViewer>,
      <span key={13} className={"mt-3"}>
        But he has locked himself up in a room and the door to the room has a
        keypad. We need to hurry and get into that room before it's too late!
      </span>,
      <span key={14} className={"mt-3"}>
        A squad member noticed a colourful clock that stopped ticking. It seems
        a bit suspicious, perhaps it's a clue to the code of the door?
      </span>,
    ],
    Content: [Step12],
  },
];

const QuestionsNL: IQuestion[] = [
  {
    Id: "1",
    Type: EQuestionType.Dropdown,
    Question: "Welke website bevat waarschijnlijk meer informatie over de bom?",
    Hints: ["", "", ""],
    Options: [
      "",
      "St Stephen's Anglican Church",
      "City Discovery",
      "Google",
      "The Engineer",
      "Wikipedia",
      "Mind Games",
      "Steve the Bartender",
      "Youtube",
      "Bjorn's website",
      "Telstra",
    ],
    Body: [
      <span key={1} style={{ color: "#7ED321", fontSize: "0.8em" }}>
        INTRO
      </span>,
      <span key={2} className={"mt-2"}>
        Ok, we hebben zojuist een telefoontje ontvangen van een terrorist die
        dreigt om een gebouw midden in Melbourne op te blazen in de komende 60
        minuten.
      </span>,
      <span key={3} className={"mt-3"}>
        We moeten de bom vinden en deze ontschadelijk maken.
      </span>,
      <span key={4}>
        Vervolgens moeten we de terrorist vinden en arresten. Tijd voor actie!
      </span>,
      <span key={5} className={"mt-3"}>
        Hier is de geluidsopname van het gesprek.
      </span>,
      <Recording
        key={6}
        header={"AUDIO"}
        path={"assests/GameFiles/recording.mp3"}
      ></Recording>,
      <Fragment key={121}>
        <span key={1} style={{ color: "#7ED321", fontSize: "0.8em" }}>
          BEWIJS
        </span>
        <span key={2} className={"mt-2"}>
          Ons technisch team heeft wat bewijs verzameld van zijn telefoon
          voordat hij deze uitschakelde.
        </span>
        <span key={3}>
          We weten dat hij ergens in het oosten van Melbourne (Australie) is,
          maar het gesprek was te kort voor een exacte lokatie.
        </span>
        <span className={"mt-2"} key={4}>
          Voordat we naar hem gaan zoeken moeten we eerst de bom vinden en deze
          ontschadelijk maken.
        </span>
        <span key={5}>
          Hopelijk bevat een van de websites meer informatie over de lokatie en
          de bom.
        </span>
        <span className={"mt-2"} key={6}>
          Kunnen jullie de websites bekijken en me vertellen welke website
          verdacht is en wellicht meer informatie bevat?
        </span>
        <FooterImage
          key={7}
          paths={[
            `${process.env.PUBLIC_URL}/assests/GameFiles/TT/evidence1.txt`,
            EvidenceImage,
          ]}
          images={[TextFileSVG, EvidencSVG]}
          itemNames={["Evidence_1.txt", "Evidence_2.png"]}
          footerText={"(klik om te vergroten)"}
        ></FooterImage>
      </Fragment>,
    ],

    Content: [Step3],
  },
  {
    Id: "2",
    Type: EQuestionType.Input,
    Question:
      "Die Bjorn Willy website is erg verdacht, maar er is een wachtwoord nodig om in te loggen. Wat is het wachtwoord?",
    Hints: ["", "", ""],
    Options: [""],
    Body: [
      <span style={{ color: "#7ED321", fontSize: "0.8em" }} key={1}>
        INTRO
      </span>,
      <span className={"mt-2"} key={2}>
        Ok, we hebben zojuist een telefoontje ontvangen van een terrorist die
        dreigt om een gebouw midden in Melbourne op te blazen in de komende 60
        minuten.
      </span>,
      <span key={3} className={"mt-3"}>
        We moeten de bom vinden en deze ontschadelijk maken.
      </span>,
      <span key={4}>
        Vervolgens moeten we de terrorist vinden en arresten. Tijd voor actie!
      </span>,
      <span key={5} className={"mt-3"}>
        Hier is de geluidsopname van het gesprek.
      </span>,
      <Recording
        key={6}
        header={"AUDIO"}
        path={"assests/GameFiles/recording.mp3"}
      ></Recording>,
      <Fragment key={121}>
        <span key={1} style={{ color: "#7ED321", fontSize: "0.8em" }}>
          BEWIJS
        </span>
        <span key={2} className={"mt-2"}>
          Ons technisch team heeft wat bewijs verzameld van zijn telefoon
          voordat hij deze uitschakelde.
        </span>
        <span key={3}>
          We weten dat hij ergens in het oosten van Melbourne (Australie) is,
          maar het gesprek was te kort voor een exacte lokatie.
        </span>
        <span key={4} className={"mt-2"}>
          Voordat we naar hem gaan zoeken moeten we eerst de bom vinden en deze
          ontschadelijk maken.
        </span>
        <span key={5}>
          Hopelijk bevat een van de websites meer informatie over de lokatie en
          de bom.
        </span>
        <FooterImage
          key={7}
          paths={[
            `${process.env.PUBLIC_URL}/assests/GameFiles/TT/evidence1.txt`,
            EvidenceImage,
          ]}
          images={[TextFileSVG, EvidencSVG]}
          itemNames={["Evidence_1.txt", "Evidence_2.png"]}
          footerText={"(klik om te vergroten)"}
        ></FooterImage>
      </Fragment>,
    ],
    Content: [Step3],
  },
  {
    Id: "3",
    Type: EQuestionType.Input,
    Question: "Wat is de lokatie van de bomb?",
    Hints: ["", "", ""],
    Options: [""],
    Body: [
      <span key={1} style={{ color: "#7ED321", fontSize: "0.8em" }}>
        WEBSITE
      </span>,
      <span key={2} className={"mt-2"}>
        Goed bezig!
      </span>,
      <span key={3}>
        Nu we toegang tot zijn{" "}
        <a
          href={"https://www.bjornwilly.co"}
          target="_blank"
          rel="noreferrer"
          style={{ color: "red" }}
        >
          <u>website</u>
        </a>{" "}
        hebben stel ik voor dat jullie opsplitsen en rondkijken.
      </span>,
      <ImageViewer
        key={4}
        linkImage={"https://www.bjornwilly.co"}
        viewerImage={Step3}
        footerText={"(klik om te openen)"}
      ></ImageViewer>,
      <Fragment key={121}>
        <span key={1} className={"mt-2"}>
          We moeten de lokatie van de bom vinden. En hopelijk vinden we ook
          informatie over de bom zelf.
        </span>
        <span key={2}>
          Laat maar weten wanneer je de lokatie hebt gevonden, dan stuur ik het
          team er naar toe.
        </span>
      </Fragment>,
    ],
    Content: [Step3],
  },
  {
    Id: "4",
    Type: EQuestionType.Input,
    Question:
      "Wat is de naam van het document dat meer informatie bevat over het ontschadelijk maken van de bom?",
    Hints: ["", "", ""],
    Options: [""],
    Body: [
      <span key={1} style={{ color: "#7ED321", fontSize: "0.8em" }}>
        KOFFER
      </span>,
      <span key={3} className={"mt-2"}>
        Ik stuur het team direct die kant op.
      </span>,
      <span key={2}>
        Ze zijn om de hoek en zijn er binnen enkele seconden...
      </span>,
      // <ModelViewer key={4} path={"TT/SuitCase/suitcasev1.glb"}></ModelViewer>,
      <ImageViewer key={4} linkImage={Step4} viewerImage={Step4}></ImageViewer>,
      <Fragment key={121}>
        <span key={1} className={"mt-2"}>
          Ok, ze zijn gearriveerd en hebben een koffer gevonden.
        </span>
        <span key={2} className={"mt-2"}>
          Laten we hopen dat een van de documenten meer informatie heeft over
          hoe we de bom ontschadelijk kunnen maken. Laat het me weten zodra je
          het document gevonden hebt!
        </span>
      </Fragment>,
    ],
    Content: [Step4],
  },
  {
    Id: "5",
    Type: EQuestionType.Input,
    Question:
      "Wat is het wachtwoord om het document te openen? (hoofdletter gevoelig)",
    Hints: ["", "", ""],
    Options: [""],
    Body: [
      <span key={1} style={{ color: "#7ED321", fontSize: "0.8em" }}>
        KOFFER
      </span>,
      <span key={2} className={"mt-2"}>
        Ok, nu we weten welk document meer informatie bevat moeten we het
        wachtwoord vinden.
      </span>,
      // <ModelViewer key={4} path={"TT/SuitCase/suitcasev1.glb"}></ModelViewer>,
      <ImageViewer key={4} linkImage={Step4} viewerImage={Step4}></ImageViewer>,
      <Fragment key={121}>
        <span key={1} className={"mt-3"}>
          Wellicht bevat een van de emails meer informatie.
          <br></br>
          Bekijk de emails om achter het wachtwoord te komen.
        </span>
      </Fragment>,
    ],
    Content: [Step4],
  },
  {
    Id: "6",
    Type: EQuestionType.Input,
    Question: "Wat is stap 1 van het ontschadelijk maken?",
    Hints: ["", "", ""],
    Options: [""],
    Body: [
      <span key={1} style={{ color: "#7ED321", fontSize: "0.8em" }}>
        DOCUMENT
      </span>,
      <span key={2} className={"mt-2"}>
        Goed werk team!
      </span>,
      <span key={3} className={"mt-2"}>
        Het{" "}
        <a
          href={`${process.env.PUBLIC_URL}/assests/GameFiles/TT/madheaven.pdf`}
          target="_blank"
          rel="noreferrer"
          style={{ color: "red" }}
        >
          <u>document</u>
        </a>{" "}
        is open en het wachtwoord was "libreville".
      </span>,
      <ImageViewer
        key={4}
        linkImage={`${process.env.PUBLIC_URL}/assests/GameFiles/TT/madheaven.pdf`}
        viewerImage={Step6}
        footerText={"(klik om te openen)"}
      ></ImageViewer>,
      <Fragment key={121}>
        <span key={11} className={"mt-2"}>
          Maar er zijn nogal wat stappen nodig om de bom ontschadelijk te maken.
        </span>
        <span key={1}>
          Gelukkig zijn jullie experts en vertrouw ik dat jullie dit wel kunnen.
        </span>
        <span key={2} className={"mt-3"}>
          Als jullie de handleiding kunnen bekijken en me laten weten welke
          stappen te volgen om de bom ontschadelijk te maken, dan informeer ik
          het team.
        </span>
      </Fragment>,
    ],

    Content: [Step6],
  },
  {
    Id: "7",
    Type: EQuestionType.CheckBox,
    Question: "Welke 3 draden moet het team doorknippen?",
    Hints: ["", "", ""],
    Options: ["W14", "W19", "W21", "W23", "W43", "W59", "W64", "W71", "W81"],
    Body: [
      <span key={1} style={{ color: "#7ED321", fontSize: "0.8em" }}>
        DRADEN
      </span>,
      <span key={2} className={"mt-2"}>
        Top, de koffer is open.
        <br></br>
        Tijd om de stappen te volgen.
      </span>,
      <ImageViewer
        key={4}
        linkImage={Step9}
        viewerImage={Step9}
        isDynamic={true}
      ></ImageViewer>,
      <Fragment key={121}>
        <span key={2} className={"mt-3"}>
          Er zijn veel draden en het team is niet zeker welke ze moeten
          doorknippen.
        </span>
        <span className={"mt-2"} key={4}>
          Maar ze vertrouwen op jullie. Bekijk de bom en de handleiding goed en
          geef vervolgens aan welke draden het team moet doorknippen.
        </span>
      </Fragment>,
      // <ModelViewer
      //   key={4}
      //   path={"TT/Bomb/timerbomb.glb"}
      //   zoomValue={2.5}
      // ></ModelViewer>,
    ],
    Content: [Step7],
  },
  {
    Id: "8",
    Type: EQuestionType.Input,
    Question: "Wat is de 4 cijferige code om de bom uit te schakelen?",
    Hints: ["", "", ""],
    Options: [""],
    InputPattern: "^[0-9]*$",
    InputMaxLimit: 4,
    Body: [
      <span key={1} style={{ color: "#7ED321", fontSize: "0.8em" }}>
        CODE
      </span>,
      <span key={2} className={"mt-2"}>
        Tot nu toe gaat het goed.
      </span>,
      <ImageViewer
        key={4}
        linkImage={Step7}
        viewerImage={Step7}
        isDynamic={true}
      ></ImageViewer>,
      <Fragment key={121}>
        <span key={1} className={"mt-2"}>
          De volgende stap is het vinden van de code. Deze is nodig om de bom
          uit te schakelen.
        </span>
        <span key={2}>Volg de instructies om de juiste code te vinden.</span>
      </Fragment>,
      // <ModelViewer
      //   key={4}
      //   path={"TT/Bomb/timerbomb.glb"}
      //   zoomValue={2.5}
      // ></ModelViewer>,
    ],
    Content: [Step7],
  },
  {
    Id: "9",
    Type: EQuestionType.Input,
    Question: "Wat is de laatste lokatie van de verdachte?",
    Hints: ["", "", ""],
    Options: [""],
    Body: [
      <span key={1} style={{ color: "#7ED321", fontSize: "0.8em" }}>
        LAATSTE LOKATIE
      </span>,
      <span key={2} className={"mt-2"}>
        Yes! Dat werkte, de bom is nu uitgeschakeld.
      </span>,
      <span key={3} className={"mt-2"}>
        Bedankt team, goed bezig. Wellicht is een carriere bij de politie iets
        voor je...
      </span>,
      <ImageViewer key={4} linkImage={Step9} viewerImage={Step9}></ImageViewer>,
      <Fragment key={121}>
        <span key={2} className={"mt-2"}>
          Tijd om de persoon te vinden die hiervoor verantwoordelijk is.
        </span>
        <span className={"mt-2"} key={4}>
          Kijk nog eens naar zijn website, wellicht staat er iets op dat te
          maken heeft met zijn laatste lokatie.
        </span>
        <span key={3}>
          Oh en kijk ook nog eens goed naar dat{" "}
          <a
            href={EvidenceImage}
            target="_blank"
            rel="noreferrer"
            style={{ color: "red" }}
          >
            <u>bewijsmateriaal</u>
          </a>
          .
        </span>
      </Fragment>,
    ],
    Content: [Step9],
  },
  {
    Id: "10",
    Type: EQuestionType.Input,
    Question: "Wat is het adres van de verdachte?",
    Hints: ["", "", ""],
    Options: [""],
    Body: [
      <span key={1} style={{ color: "#7ED321", fontSize: "0.8em" }}>
        LOKATIE
      </span>,
      <span key={2} className={"mt-2"}>
        Een team is op weg om te zien of iemand bij de kerk meer over hem weet
        en waar hij kan zijn...
      </span>,
      <ImageViewer
        key={4}
        linkImage={Step10}
        viewerImage={Step10}
      ></ImageViewer>,
      <Fragment key={121}>
        <span key={2} className={"mt-2"}>
          We komen in de buurt!
        </span>
        <span className={"mt-2"} key={4}>
          Iemand bij de kerk wist niet zijn exacte adres, maar gaf ons wel
          instructies om bij zijn huis te komen.
        </span>
        <span className={"mt-3"} key={6}>
          Kun je de instructies volgen en het adres vinden?<br></br>
          1. Start bij de kerk op 360 Church Street, Richmond, Victoria,
          Australie
          <br></br>
          2. Ga noord totdat je bij het park komt <br></br>
          3. Ga rechts en neem de 2e links
          <br></br>
          4. Ga rechts tot de rotonde<br></br>
          5. Neem de 1ste afslag en ga rechtdoor tot de splitsing <br></br>
          6. Bij de splitsing, neem de weg naar links <br></br>
          7. Tijd om goed om je heen te kijken...het is een van de huizen aan de
          rechterkant, met rode bakstenen...aan het begin
        </span>
      </Fragment>,
    ],
    Content: [Step10],
  },
  {
    Id: "11",
    Type: EQuestionType.RadioButton,
    Question:
      "Moet het team de deur intrappen of hem vragen naar buiten te komen?",
    Hints: ["", "", ""],
    Options: ["Trap de deur in", "Vraag hem naar buiten te komen"],
    Body: [
      <span key={1} style={{ color: "#7ED321", fontSize: "0.8em" }}>
        BIJ HET HUIS
      </span>,
      <span key={2} className={"mt-3"}>
        Het team is gearriveerd bij het huis.
      </span>,
      <ImageViewer
        key={4}
        linkImage={Step11}
        viewerImage={Step11}
      ></ImageViewer>,
      <span key={12} className={"mt-2"}>
        Tijd voor een snelle beslissing.
      </span>,
    ],
    Content: [Step11],
  },
  {
    Id: "12A",
    Type: EQuestionType.Input,
    Question: "Wat is de code om de deur te openen?",
    Hints: ["", "", ""],
    Options: [],
    InputPattern: "^[0-9]*$",
    InputMaxLimit: 4,
    Body: [
      <span key={1} style={{ color: "#7ED321", fontSize: "0.8em" }}>
        BINNEN
      </span>,
      <span key={2} className={"mt-3"}>
        Het team heeft de deur ingetrapt en is nu binnen, maar hij heeft
        zichzelf opgeslotenin een kamer.<br></br>De deur naar de kamer heeft een
        code nodig om te openen. We moeten opschieten!
      </span>,
      <ImageViewer
        key={4}
        linkImage={Step12}
        viewerImage={Step12}
      ></ImageViewer>,
      <span key={11} className={"mt-2"}>
        Iemand uit het team heeft een klok gezien die gestopt is met tikken.
        <br></br>
        Nogal verdacht, misschien is het een hint voor de code van de deur?
      </span>,
    ],
    Content: [Step12],
  },
  {
    Id: "12B",
    Type: EQuestionType.Input,
    Question: "Wat is de code om de deur te openen?",
    Hints: ["", "", ""],
    Options: [],
    InputPattern: "^[0-9]*$",
    InputMaxLimit: 4,
    Body: [
      <span key={1} style={{ color: "#7ED321", fontSize: "0.8em" }}>
        BINNEN
      </span>,
      <span key={2} className={"mt-3"}>
        Het team vraagt hem naar buiten te komen, maar hij weigert.
      </span>,
      <span key={3}>
        Gelukkig vindt het team een weg naar binnen via de achteringang.
      </span>,
      <ImageViewer
        key={4}
        linkImage={Step12}
        viewerImage={Step12}
      ></ImageViewer>,
      <Fragment key={121}>
        <span key={3} className={"mt-2"}>
          Maar hij heeft zichzelf opgeslotenin een kamer.<br></br>
          De deur naar de kamer heeft een code nodig om te openen. We moeten
          opschieten!
        </span>
        <span key={4} className={"mt-3"}>
          Iemand uit het team heeft een klok gezien die gestopt is met tikken.
          <br></br>
          Nogal verdacht, misschien is het een hint voor de code van de deur?
        </span>
      </Fragment>,
    ],
    Content: [Step12],
  },
];
