import { colors } from "@material-ui/core";
import React, { PropsWithChildren } from "react";
import classes from "./infoslot.module.scss";

interface IInfoSlot extends PropsWithChildren<any> {
  color?: string;
  style: React.CSSProperties;
  title: string;
  titleStyle?: React.CSSProperties;
  message: string;
  messageStyle?: React.CSSProperties;
}

const InfoSlot: React.FC<IInfoSlot> = ({
  color="",style={},title="",titleStyle={fontSize: "1.8em"},message="",messageStyle={fontSize: "0.7em"}
}) => {
  const className: string =color
    ? "info-container-" + color
    : "info-container";
  return (
    <div className={classes[className]} style={{ ...style }}>
      <span className={classes["info-title"]} style={titleStyle}>
        {title}
      </span>
      <span className={classes["info-message"]} style={messageStyle}>
        {message}
      </span>
    </div>
  );
};

// export default InfoSlot;
export default React.memo(InfoSlot, function arePropsAreEqual(prev, next) {
  return prev.title === next.title && prev.message === next.message;
});
