import React, { useState, useEffect } from "react";
import {
  GAME_CODE,
  GAME_COMPANY,
  GAME_LANGUAGE,
  GAME_TIME,
  GAME_TYPE,
} from "../../constants";
import { GameLanguages, EGameScreen } from "../../container/Enums/Enums";
import { Clamp } from "../../utilities/util";
import { GamestateProvider } from "./GameStateContext";
import { defaultGameState, IGameState } from "./GameStateInterface";
import { gameStateDefaultInterface } from "./GameStateInterface";


interface GameStateContainerProps {
  children: React.ReactNode;
}

const GameStateContainer: React.FunctionComponent<GameStateContainerProps> = ({
  children,
}) => {
  const [code, setCode] = useState<string>(gameStateDefaultInterface.gameCode);
  const [currentAnswer, setCurrentAnswer] = useState<string>(
    gameStateDefaultInterface.currentAnswer
  );
  const [gameCompany, setGameCompany] = useState<string>(
    gameStateDefaultInterface.gameCompany
  );
  const [gameType, setGameType] = useState<string>(
    gameStateDefaultInterface.gameType
  );
  const [gameLanguage, setGameLanguage] = useState<string>(
    gameStateDefaultInterface.gameCompany
  );
  const [screen, setScreen] = useState<EGameScreen>(
    gameStateDefaultInterface.screen
  );

  const [gameState, setGameState] = useState<IGameState>(defaultGameState);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      let code = localStorage.getItem(GAME_CODE);
      let type = localStorage.getItem(GAME_TYPE);
      let language = localStorage.getItem(GAME_LANGUAGE);
      setCode(code === null ? "" : code);
      setGameType(type === null ? "" : type);
      setGameLanguage(language === null ? GameLanguages.DEFAULT : language);
    }
    return () => {
      isMounted = false;
    };
  }, []);

  const deductTime = (count: number): void => {
    const clampValue = Clamp(count, 0, GAME_TIME);
    setGameState((prev) => {
      return {
        ...prev,
        gameTime: clampValue,
      };
    });
  };

  const setCurrentStepAnswer = (answer: string): void => {
    setCurrentAnswer(answer);
  };
  const setGameCode = (code: string): void => {
    localStorage.setItem(GAME_CODE, code);
    setCode(code);
  };

  const setLanguage = (language: string): void => {
    localStorage.setItem(GAME_LANGUAGE, language);
    setGameLanguage(language);
  };

  const setGameCompanyFunc = (type: string): void => {
    localStorage.setItem(GAME_COMPANY, type);
    setGameCompany(type);
  };
  const setGameTypeFunc = (type: string): void => {
    localStorage.setItem(GAME_TYPE, type);
    setGameType(type);
  };
  const setGameScreen = (screen: EGameScreen): void => {
    setScreen(screen);
  };

  const updateGameState = (state: IGameState): void => {
    setGameState((prev) => {
      return {
        ...prev,
        ...state,
      };
    });
  };
  const reset = (): void => {
    setGameScreen(gameStateDefaultInterface.screen);
    setGameCode("");
    setGameState(defaultGameState);
  };

  return (
    <GamestateProvider
      value={{
        gameCompany: gameCompany,
        gameType: gameType,
        gameLanguage: gameLanguage,
        gameCode: code,
        screen,
        gameState: gameState,
        gameStepNo: gameState.gameStepNo,
        totalIncorrect: gameState.totalIncorrect,
        totalUseHints: gameState.totalUseHints,
        useHints: gameState.useHints,
        gameTime: gameState.gameTime,
        lastHintUseTime: gameState.lastHintUsedTime,
        gameStartTime: gameState.gameStartTime,
        gameResult: gameState.result,
        currentAnswer,
        setGameCode,
        setGameType: setGameTypeFunc,
        setGameCompany: setGameCompanyFunc,
        setGameLanguage: setLanguage,
        setGameScreen,
        setCurrentStepAnswer,
        deductTime,
        updateGameState,
        reset,
      }}
    >
      {children}
    </GamestateProvider>
  );
};

export default GameStateContainer;
