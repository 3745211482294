import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import classes from "./choosepanel.module.scss";
import {
  EQuestionType,
  GameTypes,
  EMessageType,
  EGameCompany,
} from "../../../container/Enums/Enums";
import Button from "../../button/button";
import DropDown from "../../dropdown/dropdown";
import HintSlot from "../hintslot/hintslot";
import InputField from "../../inputfield/inputfield";
import CheckboxContainer from "../container/checkboxcontainer";
import { GamestateContext } from "../../../context/GameState/GameStateContext";
import { AuthContext } from "../../../context/Auth/AuthContext";
import MessageBox from "../../messagebox/messagebox";
import { IQuestion, getGameQuestionById } from "../../../metadata/TTMeta";
import NetworkManager from "../../../services/api.services";
import { AxiosResponse } from "axios";
import pusher from "../../../config/pusher";
import {
  REQUEST_HINT,
  SUBMIT_ANSWER,
  CHANNEL_JOIN_SUCCESS,
  CHANNEL_JOIN_FAILURE,
  GAME_ENDED,
  HINT_AVAILABILITY,
  GAME_ERROR,
  JUMP_END,
} from "../../../constants";
import {
  GetCompany,
  GetUpdateGameData,
  isBBHCompleted,
} from "../../../container/GameMenu/Menu";
import { HOUR_GLASS_ICON, QUESTION_MARK_ICON } from "../../../icons";
import logger, { FormatMessageByCode } from "../../../services/logger";
import { BTGameContext } from "../../../context/BTGameContext/BTGameContext";
import { Clamp, calculateMatchingPercentage } from "../../../utilities/util";
import { GetMessage } from "../../../metadata/Popup/Messages";
import DropDownsContainer from "../container/dropdownscontainer";
import { IMAGE_LYRICS } from "../../../constants";
import { LYRICS_MATCHING_PERCENTAGE } from "../../../constants";

interface IQuestionPanel {
  enableLoadingScreen: (enable: boolean) => void;
}

interface IMessageBoxClassNames {
  boxContainerClassName?: string;
  boxTitleClassName?: string;
  boxInnerContainerClassName?: string;
  boxMessageContainerClassName?: string;
  boxButtonContainerClassName?: string;
}

const ChoosePanel: React.FC<IQuestionPanel> = ({ enableLoadingScreen = () => { } }) => {
  const { gameStepNo, gameType, gameCode, gameLanguage } =
    React.useContext(GamestateContext);
  const {
    gameTime,
    useHints: UseHints,
    lastHintUseTime,
    updateGameState,
    gameState,
    currentAnswer,
    setCurrentStepAnswer,
    gameCompany,
  } = React.useContext(GamestateContext);

  const [lastMinuteRemainder, setLastMinuteRemainder] =
    React.useState<boolean>(false);
  const { logoutUser } = React.useContext(AuthContext);
  const hints = useRef<string[]>([]);
  const answerToSubmit = useRef<string[]>([]);
  const [isHintAvailable, setHintAvailability] = useState<boolean>(true);
  const timer = useRef<number>(gameTime);
  const stepNumber = useRef<string>(gameStepNo);
  const [inputValue, setInputValue] = useState<string>("");
  const [messageBoxStatus, setMessageBoxStatus] = useState<boolean>(false);
  const [messageBox, setMessageBox] = useState<React.ReactNode>(null);
  const [refresh, setRefresh] = useState<boolean>(false);
  const { recordedLyrics, ingredientsAmount, treasureFoundImage, step10ListOfHitShips } = useContext(BTGameContext);

  const question = useRef<IQuestion>(
    getGameQuestionById(gameState, gameType, gameLanguage)
  );
  const isLoading = useRef<boolean>(false);

  useEffect(() => {
    if (step10ListOfHitShips.length > 0) {
      answerToSubmit.current = step10ListOfHitShips;
      onSubmitAnswerButtonPressed(null);

    }
  }, [step10ListOfHitShips]);


  useEffect(() => {
    if (treasureFoundImage.length > 0) {
      answerToSubmit.current = treasureFoundImage;
      onSubmitAnswerButtonPressed(null);
    }
  }, [treasureFoundImage]);

  useEffect(() => {
    if (recordedLyrics.length > 0) {
      answerToSubmit.current = recordedLyrics;
    }
  }, [recordedLyrics]);

  useEffect(() => {
    if (ingredientsAmount.length > 0) {
      answerToSubmit.current = ingredientsAmount;
    }
  }, [ingredientsAmount]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      //setHints(UseHints);
      hints.current = UseHints;
    }
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    onAnswerInput(
      {
        target: {
          value: currentAnswer,
        },
      },
      EQuestionType.Component
    );
  }, [currentAnswer]);

  useEffect(() => {
    let isMounted = true;
    const interval = setInterval(() => {
      if (isMounted) {
        setRefresh(!refresh);
      }
    }, 1000);
    return () => {
      isMounted = false;
      clearInterval(interval);
    };
  }, [refresh]);

  useEffect(() => {
    timer.current = gameTime;
    stepNumber.current = gameStepNo;
  }, [gameTime, gameStepNo]);

  useEffect(() => {
    question.current = getGameQuestionById(gameState, gameType, gameLanguage);
    logger.info(
      FormatMessageByCode(
        gameCode,
        gameType + " - Choose Answer Panel - New Step No - " + gameStepNo
      )
    );
  }, [gameStepNo]);

  useEffect(() => {
    let remTime = (new Date().getTime() - lastHintUseTime) / 1000;
    if (remTime >= 0 && remTime <= HINT_AVAILABILITY) {
      setHintAvailability(false);
    } else {
      setHintAvailability(true);
    }
  }, [gameTime, hints.current, refresh]);

  useEffect(() => {
    //If 10 mints remained
    if (gameType === GameTypes.BBH && !isBBHCompleted(gameState)) {
      let minutes = gameTime / 60;
      const remainderAlarm = 10;
      if (minutes < remainderAlarm && !lastMinuteRemainder) {
        let box = getMessageBox(
          EMessageType.Error,
          "Reminder",
          [
            <span key={1}>We only have 10 minutes left.</span>,
            <span key={2}>
              Make sure to leave the bank with at least 5 minutes remaining, but
              more to be safe!
            </span>,
          ],
          <Button
            text={"CLOSE"}
            style={{ width: "235px", height: "53px", fontSize: "1em" }}
            onClick={() => {
              setMessageBoxStatus(false);
            }}
            autoFocus={true}
          ></Button>,
          null,
          HOUR_GLASS_ICON
        );
        setLastMinuteRemainder(true);
        setMessageBox(box);
        setMessageBoxStatus(true);
      }
    }
  }, [gameTime]);

  useEffect(() => {
    let isMount = true;
    if (isMount) {
      let memberChannel = pusher.subscribe(gameCode);
      memberChannel.bind(CHANNEL_JOIN_SUCCESS, () => {
        enableLoadingScreen(false);
      });

      memberChannel.bind(CHANNEL_JOIN_FAILURE, (err: any) => {
        let { status } = err;
        if (status === 408 || status === 503) {
          // retry?
          console.error("Pusher not Subscribed on channel:" + gameCode);
          memberChannel = pusher.subscribe(gameCode);
        }
      });
      memberChannel.bind(SUBMIT_ANSWER, (data: any) => {
        const { isAnswerCorrect } = data;
        updateGameState(GetUpdateGameData(data));
        if (isAnswerCorrect !== undefined) {
          if (isAnswerCorrect) {
            executeSubmitAnswerResponse(200);
            // const nextStep = StringToNumber(stepNumber.current) + 1;
            // if (parseInt(currentGameStep) === nextStep) {
            //   executeSubmitAnswerResponse(200);
            // }
          } else {
            executeSubmitAnswerResponse(400);
          }
        } else {
          resetValuesAfterSkipOrCorrectAnswer();
        }
        enableLoadingScreen(false);
        isLoading.current = false;
      });
      memberChannel.bind(JUMP_END, (data: any) => {
        logger.info(
          FormatMessageByCode(
            gameCode,
            gameType + " - Choose Answer Panel - Jump End Trigger"
          )
        );
        answerToSubmit.current = [];
        hints.current = [];
        updateGameState(GetUpdateGameData(data));
        enableLoadingScreen(false);
        isLoading.current = false;
      });
      memberChannel.bind(REQUEST_HINT, (data: any) => {
        logger.info(
          FormatMessageByCode(
            gameCode,
            gameType + " - Choose Answer Panel - Hint Request Trigger"
          )
        );
        const { hintRequested } = data;
        if (hintRequested !== undefined) {
          if (!hints.current.includes(hintRequested)) {
            hints.current = hints.current.concat([hintRequested]);

            logger.info(
              FormatMessageByCode(
                gameCode,
                gameType + " - Choose Answer Panel - Added Hint To the List"
              )
            );
          }
          updateGameState(GetUpdateGameData(data));
          //deductTime(timer.current - 30);
          enableLoadingScreen(false);
        }
      });
      memberChannel.bind(GAME_ENDED, (data: any) => {
        logger.info(
          FormatMessageByCode(
            gameCode,
            gameType + " - Choose Answer Panel - Game End Trigger"
          )
        );
        answerToSubmit.current = [];
        hints.current = [];
        updateGameState(GetUpdateGameData(data));
        //moveToNextStep(1);
      });

      memberChannel.bind(GAME_ERROR, (data: any) => {
        enableLoadingScreen(false);
        logger.info(
          FormatMessageByCode(
            gameCode,
            gameType +
            " - Choose Answer Panel - Something went wrong on the Server"
          )
        );
      });
      return () => {
        console.log("Unmounted ChoosePanel");
        isMount = false;
        //pusher.unsubscribe(gameCode);
        memberChannel.unbind_all();
      };
    }
  }, []);

  const onKeyDownEvent = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      if (!isSubmitButtonDisable() && isLoading.current === false) {
        isLoading.current = true;
        onSubmitAnswerButtonPressed(null);
      }
    }
  };
  const onAnswerInput = (event: any, type: EQuestionType) => {
    switch (type) {
      case EQuestionType.Dropdown:
        //setSubmitAnswer([event.target.value]);
        answerToSubmit.current = [event.target.value];
        setInputValue(event.target.value);
        break;
      case EQuestionType.Dropdowns:
        answerToSubmit.current = [event];
        setInputValue(event);
        break;
      case EQuestionType.InputButton:
        const limit = question.current.InputMaxLimit || 200;
        if (inputValue.length < limit) {
          answerToSubmit.current = [inputValue + event.target.value];
          setInputValue(inputValue + event.target.value);
        }
        break;
      case EQuestionType.Input:
        //setSubmitAnswer([event.target.value]);
        answerToSubmit.current = [event.target.value];
        setInputValue(event.target.value);
        break;
      case EQuestionType.CheckBox:
        if (event.target.checked) {
          //setSubmitAnswer([...getSubmitAnswer, event.target.value]);
          answerToSubmit.current = [
            ...answerToSubmit.current,
            event.target.value,
          ];
        } else {
          let checkboxList = [...answerToSubmit.current];
          checkboxList = checkboxList.filter((x) => {
            return x !== event.target.value;
          });
          //setSubmitAnswer([...checkboxList]);
          answerToSubmit.current = [...checkboxList];
        }
        break;
      case EQuestionType.RadioButton:
        //setSubmitAnswer([event.target.value]);
        setInputValue(event.target.value);
        answerToSubmit.current = [event.target.value];
        break;
      case EQuestionType.Component:
        setInputValue(event.target.value);
        answerToSubmit.current = [event.target.value];
        break;
      default:
        return null;
    }
  };

  const getQuestionType = (question: IQuestion): React.ReactNode => {
    const type = question.Type;
    switch (type) {
      case EQuestionType.Dropdown:
        return (
          <DropDown
            options={question.Options}
            onChange={(e) => {
              onAnswerInput(e, EQuestionType.Dropdown);
            }}
          ></DropDown>
        );
      case EQuestionType.Dropdowns:
        return (
          <DropDownsContainer
            options={question.DropDownsOptions}
            onChange={(e) => {
              onAnswerInput(e, EQuestionType.Dropdowns);
            }}
          ></DropDownsContainer>
        );
      case EQuestionType.InputButton:
        return (
          <Fragment>
            <div className={classes["button-container"]}>
              <div className={`${classes["input-button-container"]} mr-2`}>
                <Button
                  style={{ width: "55px", height: "58px", borderRadius: "0px" }}
                  text="N"
                  onClick={() => {
                    onAnswerInput({ target: { value: "N" } }, type);
                  }}
                ></Button>
                <Button
                  style={{ width: "55px", height: "58px", borderRadius: "0px" }}
                  text="E"
                  onClick={() => {
                    onAnswerInput({ target: { value: "E" } }, type);
                  }}
                ></Button>
                <Button
                  style={{ width: "55px", height: "58px", borderRadius: "0px" }}
                  text="S"
                  onClick={() => {
                    onAnswerInput({ target: { value: "S" } }, type);
                  }}
                ></Button>
                <Button
                  style={{ width: "55px", height: "58px", borderRadius: "0px" }}
                  text="W"
                  onClick={() => {
                    onAnswerInput({ target: { value: "W" } }, type);
                  }}
                ></Button>
              </div>
              <Button
                style={{ width: "131px", height: "58px" }}
                text="Clear"
                color="grey"
                onClick={() => {
                  answerToSubmit.current = [];
                  setInputValue("");
                }}
              ></Button>
            </div>
            Your Answer:{" "}
            {answerToSubmit.current.length === 0
              ? ""
              : answerToSubmit.current[0]}
          </Fragment>
        );
      case EQuestionType.Input:
        return (
          <InputField
            onKeyDown={onKeyDownEvent}
            onValueChanged={(e) => {
              onAnswerInput(e, EQuestionType.Input);
            }}
            value={
              answerToSubmit.current.length === 0
                ? ""
                : answerToSubmit.current[0]
            }
            pattern={question.InputPattern ? question.InputPattern : ""}
            maxLength={question.InputMaxLimit ? question.InputMaxLimit : 80}
            style={{ width: "460px" }}
          ></InputField>
        );
      case EQuestionType.CheckBox:
        return (
          <CheckboxContainer
            options={question.Options}
            onChange={(e) => {
              onAnswerInput(e, EQuestionType.CheckBox);
            }}
          ></CheckboxContainer>
        );
      case EQuestionType.RadioButton:
        return (
          <CheckboxContainer
            isCheckbox={false}
            options={filterRadioOptions(question.Options)}
            OnValueChanged={(e) => {
              onAnswerInput(e, EQuestionType.RadioButton);
            }}
          ></CheckboxContainer>
        );
      case EQuestionType.Component:
        return question.CustomInputType;
      case EQuestionType.None:
        return <React.Fragment></React.Fragment>;
      default:
        return <DropDown options={["DUMMY", "DUMMY", "DUMMY"]}></DropDown>;
    }
  };

  const filterRadioOptions = (options: any) => {
    if (gameType === GameTypes.BBH && gameState.gameContinued) {
      return options.filter((option: string) => option !== "Escape the bank");
    }
    return options;
  };

  const onBTSubmitRecordingAnswerButtonPressed = async (event: any) => {
    const percentage = calculateMatchingPercentage(
      IMAGE_LYRICS,
      answerToSubmit.current[0]
    );
    if (parseInt(percentage) > LYRICS_MATCHING_PERCENTAGE) {
      answerToSubmit.current = ["Hoorah"];
    } else {
      answerToSubmit.current = ["invalid"];
    }
    onSubmitAnswerButtonPressed(event);
  };

  const resetValuesAfterSkipOrCorrectAnswer = () => {
    answerToSubmit.current = [];
    hints.current = [];
    setInputValue("");
    setCurrentStepAnswer("");
  };

  const onSubmitAnswerButtonPressed = async (event: any) => {
    switch (question.current.Type) {
      case EQuestionType.Dropdown:
      case EQuestionType.RadioButton:
        if (answerToSubmit.current.length <= 0) {
          answerToSubmit.current = [question.current.Options[0]];
        }
        break;
      default:
        if (answerToSubmit.current.length <= 0) return;
        break;
    }
    var response = null;
    enableLoadingScreen(true);
    answerToSubmit.current[0] = answerToSubmit.current[0].trim();
    try {
      logger.info(
        FormatMessageByCode(
          gameCode,
          gameType + " - Choose Answer Panel - Submit Answer Request"
        )
      );
      response = await NetworkManager.SubmitAnswer(
        stepNumber.current,
        answerToSubmit.current,
        gameType,
        gameLanguage
      );
      return;
    } catch (err: any) {
      response = err.response as AxiosResponse;
    }
    if (response.status === 400) {
      logger.info(
        FormatMessageByCode(
          gameCode,
          gameType + " - Choose Answer Panel - Submit Answer Error - 400"
        )
      );
      return;
    }
    enableLoadingScreen(false);
    executeResponseCode(response);
  };

  const onRequestHintButtonPressed = async (event: any) => {
    if (hints.current.length >= 4) return;
    enableLoadingScreen(true);
    var response = null;
    try {
      logger.info(
        FormatMessageByCode(
          gameCode,
          gameType + " - Choose Answer Panel - Hint Request"
        )
      );
      response = await NetworkManager.RequestHint(
        stepNumber.current,
        gameType,
        gameLanguage
      );
    } catch (err: any) {
      response = err.response;
      enableLoadingScreen(false);
    }
    let box = null;
    if (response?.status === 200) {
      // setHints(hints.concat([response.data.hint]));
      // setHintCount(getHintCount + 1);
      return;
    } else if (response?.status === 400) {
      box = getMessageBox(
        EMessageType.Error,
        "Error",
        [<span>{response.data.message}</span>],
        <Button
          text={"CLOSE"}
          style={{ width: "235px", height: "53px", fontSize: "1em" }}
          onClick={() => setMessageBoxStatus(false)}
          autoFocus={true}
        ></Button>
      );
      setMessageBox(box);
      setMessageBoxStatus(true);
    }

    executeResponseCode(response);
  };

  const onSkipButtonPressed = async (event: any) => {
    let box = getMessageBox(
      EMessageType.Error,
      "Skip challenge",
      [
        <span key={1}>Are you sure you want to skip this challenge?</span>,
        <span key={2}>This results in a 120 second time penalty.</span>,
      ],
      <Button
        text={"SKIP"}
        style={{ width: "221px", height: "58px", fontSize: "1em" }}
        icon={<i className="fas fa-running"></i>}
        onClick={() => onSkipMessageBoxClick(true)}
        autoFocus={true}
      ></Button>,
      <Button
        text={"CANCEL"}
        color={"grey"}
        style={{ width: "221px", height: "58px", fontSize: "1em" }}
        onClick={() => onSkipMessageBoxClick(false)}
        autoFocus={true}
      ></Button>,
      QUESTION_MARK_ICON,
      {
        boxContainerClassName: classes["skip-challenge-box-container"],
        boxTitleClassName: classes["skip-challenge-box-title"],
        boxMessageContainerClassName:
          classes["skip-challenge-box-message-container"],
        boxButtonContainerClassName:
          classes["skip-challenge-box-button-container"],
      }
    );
    setMessageBox(box);
    setMessageBoxStatus(true);
  };

  const onSkipMessageBoxClick = async (value: boolean) => {
    if (value) {
      setMessageBoxStatus(false);
      enableLoadingScreen(true);
      var response = null;
      try {
        logger.info(
          FormatMessageByCode(
            gameCode,
            gameType + " - Choose Answer Panel - Skip Button Click"
          )
        );
        response = await NetworkManager.SubmitAnswer(
          gameStepNo,
          ["skipChallenge"],
          gameType,
          gameLanguage
        );
        return;
      } catch (err: any) {
        response = err.response as AxiosResponse;
      }
      if (response.status === 400) {
        logger.info(
          FormatMessageByCode(
            gameCode,
            gameType + " - Choose Answer Panel - Skip Button Error - 400"
          )
        );
        return;
      }
      enableLoadingScreen(false);
      executeResponseCode(response);
    }
    setMessageBoxStatus(false);
  };

  const onEscapeButtonPressed = async (event: any) => {
    let box = getMessageBox(
      EMessageType.Error,
      "Escape the bank",
      [
        <span key={1}>Are you sure you want to escape the bank?</span>,
        <span key={2} className="mt-2">
          Once you escape there is no way back.
        </span>,
      ],
      <Button
        text={"ESCAPE"}
        style={{ width: "235px", height: "53px", fontSize: "1em" }}
        icon={<i className="fas fa-running"></i>}
        onClick={() => onEscapeMessageBoxClick(true)}
        autoFocus={true}
      ></Button>,
      <Button
        text={"CANCEL"}
        color={"grey"}
        style={{ width: "235px", height: "53px", fontSize: "1em" }}
        onClick={() => onEscapeMessageBoxClick(false)}
        autoFocus={true}
      ></Button>,
      QUESTION_MARK_ICON
    );
    setMessageBox(box);
    setMessageBoxStatus(true);
  };

  const onEscapeMessageBoxClick = async (value: boolean) => {
    if (value) {
      setMessageBoxStatus(false);
      enableLoadingScreen(true);
      var response = null;
      try {
        logger.info(
          FormatMessageByCode(
            gameCode,
            gameType + " - Choose Answer Panel - Escape Button Click"
          )
        );
        response = await NetworkManager.SubmitAnswer(
          "jumpToEnd",
          [],
          gameType,
          gameLanguage
        );
        return;
      } catch (err: any) {
        response = err.response as AxiosResponse;
      }
      if (response.status === 400) {
        logger.info(
          FormatMessageByCode(
            gameCode,
            gameType + " - Choose Answer Panel - Escape Button Error - 400"
          )
        );
        return;
      }
      enableLoadingScreen(false);
      executeResponseCode(response);
    }
    setMessageBoxStatus(false);
  };
  const onMessageBoxButtonPressed = (value: boolean) => {
    if (value) {
      //setSubmitAnswer([]);
      answerToSubmit.current = [];
    }
    setMessageBoxStatus(false);
  };

  const executeSubmitAnswerResponse = (code: number, message?: string) => {
    let box = null;

    if (code === 200) {
      let content = GetMessage(
        gameType,
        gameCompany,
        "success",
        stepNumber.current
      );
      box = getMessageBox(
        EMessageType.Success,
        "Success",
        content,
        <Button
          text={"CONTINUE"}
          timer={GetCompany() === EGameCompany.Reckitt ? 5 : 0}
          style={{
            width: "235px",
            height: "53px",
            fontSize: "1em",
            marginBottom: "10px",
          }}
          onClick={() => onMessageBoxButtonPressed(true)}
          autoFocus={true}
        ></Button>
      );
      resetValuesAfterSkipOrCorrectAnswer();
    } else if (code === 400) {
      let content = GetMessage(
        gameType,
        gameCompany,
        "failure",
        stepNumber.current
      );
      box = getMessageBox(
        EMessageType.Error,
        "Incorrect",
        content,
        <Button
          text={"CLOSE"}
          timer={GetCompany() === EGameCompany.Reckitt ? 5 : 0}
          style={{
            width: "235px",
            height: "53px",
            fontSize: "1em",
            marginBottom: "10px",
          }}
          onClick={() => onMessageBoxButtonPressed(false)}
          autoFocus={true}
        ></Button>
      );
      //deductTime(timer.current - 30);
      //setIncorrectAnswers(1);
    } else if (code === 500) {
      box = getMessageBox(
        EMessageType.Error,
        "Something went wrong",
        [
          message === undefined ? (
            <span key={1}></span>
          ) : (
            <span key={1}>{message}</span>
          ),
        ],
        <Button
          text={"CONTINUE"}
          timer={GetCompany() === EGameCompany.Reckitt ? 5 : 0}
          style={{ width: "235px", height: "53px", fontSize: "1em" }}
          onClick={() => onMessageBoxButtonPressed(false)}
          autoFocus={true}
        ></Button>
      );
    }
    setMessageBox(box);
    setMessageBoxStatus(true);
  };

  const executeResponseCode = (response: AxiosResponse) => {
    if (response?.status === 401) {
      let box = getMessageBox(
        EMessageType.Error,
        "Something went wrong",
        [<span>Please re-login to renew your session.</span>],
        <Button
          text={"CONTINUE"}
          style={{ width: "235px", height: "53px", fontSize: "1em" }}
          onClick={() => logoutUser()}
          autoFocus={true}
        ></Button>
      );
      setMessageBox(box);
      setMessageBoxStatus(true);
    }
  };
  const isSubmitButtonDisable = (): boolean => {
    if (answerToSubmit.current.length > 0) {
      return answerToSubmit.current.every((x) => x.trim() === "");
    }
    return true;
  };

  const getBBHGamePanel = (): React.ReactNode => {
    return (
      <React.Fragment>
        <div className={classes["BBH-button-container"]}>
          {getQuestionType(question.current)}
          <Button
            style={{ width: "221px", height: "53px", margin: "5px 5px 0 5px" }}
            text="SUBMIT ANSWER"
            onClick={onSubmitAnswerButtonPressed}
            disabled={isSubmitButtonDisable()}
            hidden={question.current.Type === EQuestionType.None}
          ></Button>
        </div>
        <div className={classes["button-container"]}>
          <Button
            style={{ width: "221px", height: "58px" }}
            text="REQUEST HINT"
            color="grey"
            onClick={onRequestHintButtonPressed}
            disabled={hints.current.length >= 4 || !isHintAvailable}
            hidden={question.current.Type === EQuestionType.None}
          ></Button>
          {!isBBHCompleted(gameState) ||
            question.current.Type === EQuestionType.None ? (
            <Button
              style={{ width: "221px", height: "58px", margin: "0 0 0 10px" }}
              onClick={onEscapeButtonPressed}
              text="ESCAPE THE BANK"
              hidden={gameStepNo === "12"}
              icon={<i className="fas fa-running"></i>}
            ></Button>
          ) : null}
        </div>
      </React.Fragment>
    );
  };
  const getTTGamePanel = (): React.ReactNode => {
    return (
      <React.Fragment>
        {getQuestionType(question.current)}
        <div className={classes["button-container"]}>
          <Button
            style={{ width: "221px", height: "58px" }}
            text="REQUEST HINT"
            color="grey"
            onClick={onRequestHintButtonPressed}
            disabled={hints.current.length >= 4 || !isHintAvailable}
          ></Button>
          <Button
            style={{ width: "221px", height: "58px", margin: "0 0 0 10px" }}
            text="SUBMIT ANSWER"
            onClick={onSubmitAnswerButtonPressed}
            disabled={isSubmitButtonDisable()}
          ></Button>
        </div>
      </React.Fragment>
    );
  };

  const getBTGamePanel = (): React.ReactNode => {
    if (
      !isBTGameStep4(gameType, gameStepNo) &&
      !isBTGameStep8(gameType, gameStepNo) && !isBTGameStep10(gameType, gameStepNo)
    )
      return getTTGamePanel();
    else if (isBTGameStep4(gameType, gameStepNo)) {
      return (
        <React.Fragment>
          <span className="mt-4">
            Not a great singer?
            <br />
            Skip this challenge at the cost of a 2-minute time penalty.
            <br></br>
            <br></br>
          </span>
          {getQuestionType(question.current)}
          <div className={classes["button-container"]}>
            <Button
              style={{ width: "221px", height: "58px" }}
              text={"SKIP CHALLENGE"}
              color="grey"
              onClick={onSkipButtonPressed}
              disabled={false}
            />
            <Button
              style={{ width: "221px", height: "58px", margin: "0 0 0 10px" }}
              text={"SUBMIT RECORDING"}
              onClick={onBTSubmitRecordingAnswerButtonPressed}
              disabled={isSubmitButtonDisable()}
            />
          </div>
        </React.Fragment>
      );
    }
    else if (isBTGameStep8(gameType, gameStepNo)) {
      return (
        <React.Fragment>
          <div className={classes["BT-step9"]}>{getTTGamePanel()}</div>
        </React.Fragment>
      );
    }
    else {
      return (
        <div className={classes["button-container"]} style={{ marginTop: "89px" }}>
          <Button
            style={{ width: "221px", height: "58px" }}
            text="REQUEST HINT"
            color="grey"
            onClick={onRequestHintButtonPressed}
            disabled={hints.current.length >= 4 || !isHintAvailable}
          ></Button>
          <Button
            style={{ width: "221px", height: "58px", margin: "0 0 0 10px" }}
            text={"SUBMIT ANSWER"}
            onClick={onBTSubmitRecordingAnswerButtonPressed}
            disabled={true}
          />
        </div>
      );
    }
  };
  const getChoosePanelByGame = (): React.ReactNode => {
    switch (gameType) {
      case GameTypes.JH:
      case GameTypes.TT:
      case GameTypes.TTJr:
      case GameTypes.TTNL:
      case GameTypes.MAYDAY:
        return getTTGamePanel();
      case GameTypes.BT:
        return getBTGamePanel();
      case GameTypes.BBH:
        return getBBHGamePanel();
      default:
        return getTTGamePanel();
    }
  };
  const hintComponents = hints.current.map((x, index) => {
    return <HintSlot key={index} hintNo={index} text={x}></HintSlot>;
  });
  return (
    <React.Fragment>
      {messageBoxStatus ? messageBox : null}
      <div className={classes["question-panel"]}>
        <span style={{ color: "#7ED321", fontSize: "0.8em" }}>
          THE CHALLENGE
        </span>
        <span className="mt-2" style={{ width: "90%" }}>
          {question.current.Question}
        </span>
        {getChoosePanelByGame()}
        {hintComponents}
      </div>
    </React.Fragment>
  );
};

export default ChoosePanel;

export const getMessageBox = (
  type: EMessageType,
  title: string,
  message: React.ReactNode[],
  button: React.ReactNode,
  button2?: React.ReactNode,
  titleIcon?: string,
  classNames?: IMessageBoxClassNames
) => {
  const {
    boxContainerClassName,
    boxTitleClassName,
    boxInnerContainerClassName,
    boxMessageContainerClassName,
    boxButtonContainerClassName,
  } = classNames || {};

  return (
    <MessageBox
      title={title}
      type={type}
      button1={button}
      button2={button2}
      message={message}
      titleIcon={titleIcon}
      boxContainerClassName={boxContainerClassName}
      boxTitleClassName={boxTitleClassName}
      boxInnerContainerClassName={boxInnerContainerClassName}
      boxMessageContainerClassName={boxMessageContainerClassName}
      boxButtonContainerClassName={boxButtonContainerClassName}
    ></MessageBox>
  );
};

export const isBTGameStep4 = (gameType: string, step: string) => {
  return gameType === GameTypes.BT && step === "4";
};

export const isBTGameStep8 = (gameType: string, step: string) => {
  return gameType === GameTypes.BT && step === "8";
};

export const isBTGameStep10 = (gameType: string, step: string) => {
  return gameType === GameTypes.BT && step === "10";
};
