import React, {
  Fragment,
  useState,
  useEffect,
  useRef,
  useContext,
} from "react";
import classes from "./Leaderboard.module.scss";
import { useNavigate } from "react-router-dom";
import Container from "../../components/game/container/container";
import { CircularSpinner } from "../../components/Spinner/Spinner";
import Button from "../../components/button/button";
import LeaderboardSlot from "../../components/leaderboard/teamslot";
import LeaderboardHeader from "../../components/leaderboard/leaderboardheader";
import { ROUTE_MENU } from "../../constants";
import NetworkManager from "../../services/api.services";
import { GamestateContext } from "../../context/GameState/GameStateContext";
import { getFullGameName } from "../../components/game/hud/hud";
import { getGameBGByScreen } from "../../metadata/TTMeta";
import "../../Background.scss";
import { GameTypes } from "../Enums/Enums";
import logger, { FormatMessageByCode } from "../../services/logger";
interface ILeaderboardItems {
  rankings: ILeaderboardItem[];
}
interface ILeaderboardItem {
  rank: number;
  noHintsUsed: number;
  noIncorrectAnswers: number;
  result: string;
  teamName: string;
  companyName: string;
}
const Leaderboard: React.FC = () => {
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate()
  let leaderboardData = useRef<ILeaderboardItem[]>([]);

  const { gameType, gameLanguage, gameCode } = useContext(GamestateContext);
  // const items: ILeaderboardItems = history.location.state as ILeaderboardItems;
  // const rankings: ILeaderboardItem[] = items.rankings;

  useEffect(() => {
    fetchAndMoveToLeaderboard();
  }, []);

  const fetchAndMoveToLeaderboard = async () => {
    setLoading(true);
    try {
      logger.info(
        FormatMessageByCode(
          gameCode,
          gameType + " - Leaderboard - Fetching Leaderboard"
        )
      );
      const response = await NetworkManager.RequestLeaderboard(
        gameType,
        gameLanguage
      );
      const items: ILeaderboardItems = response.data as ILeaderboardItems;
      // var ranks = items.rankings.sort((a, b) => {
      //   return a.rank - b.rank;
      // });
      leaderboardData.current = items.rankings.slice(0, 25);
    } catch (err) {
      logger.info(
        FormatMessageByCode(
          gameCode,
          gameType + " - Leaderboard - Fetching Leaderboard Error"
        )
      );
    }
    setLoading(false);
  };

  // const playerData =
  //   leaderboardData.current.length > 0 ? leaderboardData.current : rankings;
  const leaderboardSlots = leaderboardData.current.map((value, index) => {
    return (
      <LeaderboardSlot
        key={index}
        Id={index + 1}
        name={value.teamName}
        companyName={value.companyName}
        rank={value.rank}
        hints={value.noHintsUsed}
        incorrect={value.noIncorrectAnswers}
        result={ParseResultByGame(gameType, value.result)}
        hasBorder={leaderboardData.current.length === index + 1}
      ></LeaderboardSlot>
    );
  });
  return (
    <Fragment>
      {isLoading ? <CircularSpinner></CircularSpinner> : null}
      <header
        className={`${classes["menu-header"]} ${getGameBGByScreen(
          "leaderboard",
          gameType
        )}`}
      >
        <Container>
          <div className={classes["slot-container"]}>
            <div className={classes["game-text"]}>
              <span>{getFullGameName(gameType)}</span>
            </div>
            <LeaderboardHeader></LeaderboardHeader>
            {leaderboardSlots}
            <Button
              className="mt-5"
              text="RETURN TO MAIN MENU"
              rightIcon={<i className="fas fa-arrow-left"></i>}
              style={{
                fontSize: "1em",
                alignSelf: "center",
                width: "386px",
                height: "58px",
              }}
              onClick={() => {
                navigate(ROUTE_MENU);
              }}
            ></Button>
          </div>
        </Container>
      </header>
    </Fragment>
  );
};

export default Leaderboard;

export const ParseResultByGame = (gameType: string, result: string): string => {
  switch (gameType) {
    case GameTypes.BBH:
      const res = parseInt(result) || 0;
      return "$" + res.toLocaleString("en") + ".00";
    default:
      return result;
  }
};
