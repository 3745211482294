import { EnemyShips, FriendlyShips } from "../../container/Enums/Enums";

export interface IBTGame {
  step10ListOfHitShips:(EnemyShips|FriendlyShips)[],
  treasureFoundImage: string[],
  recordedLyrics: string[];
  ingredientsAmount: string[];
  ingredientVideo: string;
  playAgainIngredientVideo: number;
  isBGAudioPlaying: boolean;
  isBtGameStep4: boolean;
  updateRecordedLyrics: (lyrics: string[]) => void;
  updateIngredientsAmount: (amount: string[]) => void;
  updateIngredientVideo: (url: string) => void;
  updatePlayAgainIngredientVideo: (time: number) => void;
  updateIsBGAudioPlaying: (playOrPause: boolean) => void;
  updateIsBtGameStep4: (toggle: boolean) => void;
  updateTreasureFoundImage: (treasureImage: string[]) => void;
  updateStep10ListOfHitShips: (hitsShips:(EnemyShips|FriendlyShips)[]) => void;
}

export const defaultBTGame: IBTGame = {
  step10ListOfHitShips:[],
  treasureFoundImage: [],
  recordedLyrics: [],
  ingredientsAmount: [],
  ingredientVideo: "",
  playAgainIngredientVideo: Date.now(),
  isBGAudioPlaying: true,
  isBtGameStep4: false,
  updateRecordedLyrics: (lyrics: string[]) => { },
  updateIngredientsAmount: (amount: string[]) => { },
  updateIngredientVideo: (url: string) => { },
  updatePlayAgainIngredientVideo: (time: number) => { },
  updateIsBGAudioPlaying: (playOrPause: boolean) => { },
  updateIsBtGameStep4: (toggle: boolean) => { },
  updateTreasureFoundImage: (treasureImage: string[]) => { },
  updateStep10ListOfHitShips: (hitsShips:(EnemyShips|FriendlyShips)[]) => { },
};
