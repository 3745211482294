import React from "react";
import SlideShow from "../../game/imagepanel/ImageSlideShow";
import { CSSProperties } from "@material-ui/core/styles/withStyles";

interface IStep5Slider {
  viewerImage: any;
  footerText?: string;
  defaultScale?: number;
  style?: CSSProperties;
}

const Step5Slider: React.FC<IStep5Slider> = ({
  viewerImage,
  footerText,
  defaultScale,
  style,
}) => {
  return (
    <SlideShow
      viewerImage={viewerImage}
      footerText={footerText}
      defaultScale={defaultScale}
      style={style}
      leftIconClass="fal fa-arrow-circle-left  ml-4"
      rightIconClass="fal fa-arrow-circle-right  mr-4"
      iconStyles={{
        color: "white",
        backgroundColor: "red",
        borderRadius: "50%",
      }}
    ></SlideShow>
  );
};

export default Step5Slider;
