import React, { useState, useRef, useEffect } from "react";
import Button from "../button/button";
import classes from "./audioRecorder.module.scss";
import RedDot from "../../assets/BlackeyeTreasure/Step5_ellipse.svg";
import { useContext } from "react";
import { BTGameContext } from "../../context/BTGameContext/BTGameContext";
import { ResultReason } from "microsoft-cognitiveservices-speech-sdk";
import * as speechsdk from "microsoft-cognitiveservices-speech-sdk";

const speechConfig = speechsdk.SpeechConfig.fromSubscription(
  process.env.REACT_APP_SPEECH_SUBSCRIPTION_KEY ?? "",
  process.env.REACT_APP_SPEECH_REGION ?? ""
);
speechConfig.speechRecognitionLanguage = "en-US";

const AudioRecorder: React.FC = () => {
  const [recording, setRecording] = useState(false);
  const [audioURL, setAudioURL] = useState("");
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [transcript, setTranscript] = useState("");
  const [speechRecognizer, setSpeechRecognizer] =
    useState<speechsdk.SpeechRecognizer | null>(null);

  const {
    isBGAudioPlaying,
    updateRecordedLyrics,
    updateIsBGAudioPlaying,
    updateIsBtGameStep4,
  } = useContext(BTGameContext);
  const musicStateBeforeThisStep = useRef<boolean>(isBGAudioPlaying);

  useEffect(() => {
    const audioConfig = speechsdk.AudioConfig.fromDefaultMicrophoneInput();
    const recognizer = new speechsdk.SpeechRecognizer(
      speechConfig,
      audioConfig
    );
    setSpeechRecognizer(recognizer);

    setTimeout(() => {
      updateIsBtGameStep4(true);
      if (isBGAudioPlaying) {
        updateIsBGAudioPlaying(false);
      }
      musicStateBeforeThisStep.current = isBGAudioPlaying;
    }, 700);

    return () => {
      updateIsBtGameStep4(false);
      if (musicStateBeforeThisStep.current) {
        updateIsBGAudioPlaying(true);
      }
      updateRecordedLyrics([])
    };
  }, []);

  useEffect(() => {
    if (transcript && transcript !== "") {
      updateRecordedLyrics([transcript]);
    }
  }, [transcript, updateRecordedLyrics]);

  useEffect(() => {
    if (recording) {
      setIsPlaying(false);
    }
  }, [recording]);

  const handleRecord = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorderRef.current = mediaRecorder;
      const chunks: Blob[] = [];

      mediaRecorder.addEventListener("dataavailable", (e) => {
        chunks.push(e.data);
      });

      mediaRecorder.addEventListener("stop", () => {
        const blob = new Blob(chunks, { type: "audio/webm" });
        const url = URL.createObjectURL(blob);
        setAudioURL(url);
      });

      mediaRecorder.start();
      setRecording(true);
      if (speechRecognizer) {
        speechRecognizer.recognized = (sender: any, event: any) => {
          const result = event.result;
          if (result.text && result.reason === ResultReason.RecognizedSpeech) {
            setTranscript(`${result.text}`);
          } else {
            setTranscript("");
          }
        };
        speechRecognizer.startContinuousRecognitionAsync();
      }
      timeoutRef.current = setTimeout(handleStop, 30000);
    } catch (error: any) {
      if (error.name === "NotAllowedError") {
        alert(
          "Microphone access is blocked for this site. kindly allow access to microphone."
        );
      } else {
        console.error("Error accessing microphone:", error);
      }
    }
  };

  const handleStop = () => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      const stream = mediaRecorderRef.current.stream;
      if (stream) {
        const tracks = stream.getTracks();
        tracks.forEach((track) => track.stop());
      }
      setRecording(false);
      if (speechRecognizer) {
        speechRecognizer.stopContinuousRecognitionAsync();
      }
    }
  };

  const handlePlayback = () => {
    if (recording) {
      return;
    }
    const audioElement = audioRef.current;
    if (audioElement) {
      if (isPlaying) {
        audioElement.pause();
        setIsPlaying(false);
      } else {
        audioElement.play();
        setIsPlaying(true);
      }
    }
  };

  const handlePlaybackEnd = () => {
    setIsPlaying(false);
  };

  return (
    <div className={`${classes["audio-button-container"]}`}>
      <Button
        className="mt-2"
        color="grey"
        text={recording ? "STOP RECORDING" : "RECORD"}
        rightIcon={<img src={RedDot} alt="eclipse"></img>}
        style={{
          fontSize: "0.8em",
          alignSelf: "center",
          width: "200px",
          height: "50px",
          margin: "0 0 0 10px",
        }}
        onClick={recording ? handleStop : handleRecord}
      ></Button>

      <Button
        className="mt-2"
        text="PLAY BACK"
        rightIcon={
          isPlaying ? (
            <i className="fas fa-pause"></i>
          ) : (
            <i className="fas fa-play"></i>
          )
        }
        style={{
          fontSize: "0.8em",
          alignSelf: "center",
          width: "200px",
          height: "50px",
          margin: "0 0 0 10px",
        }}
        onClick={handlePlayback}
      ></Button>
      <audio ref={audioRef} src={audioURL} onEnded={handlePlaybackEnd} />
    </div>
  );
};

export default AudioRecorder;
