import React from "react";
import { EGameCompany } from "../../container/Enums/Enums";
import { isCompany } from "../../container/GameMenu/Menu";
import classes from "./teamslot.module.scss";

interface ITeamSlot extends React.HTMLAttributes<HTMLDivElement> {
  Id: number;
  rank: number;
  name: string;
  companyName: string;
  hints: number;
  incorrect: number;
  result: string;
  hasBorder?: boolean;
  isEmpty?: boolean;
}

const TeamSlot: React.FC<ITeamSlot> = ({
  Id = 1,
  rank = 1,
  name = "TEAM 1",
  companyName = "",
  hints = 88,
  incorrect = 888,
  result = "0000",
  hasBorder = false,
  isEmpty = false,
}) => {
  const bgColor = Id % 2 === 0 ? "#0E2634" : "#2D4955";
  const border = hasBorder ? "1px" : "0";
  const styles = { backgroundColor: bgColor, borderBottomWidth: border };
  return (
    <div className={classes["item-slot"]} style={styles}>
      <span className={classes["rank-slot"]}>{isEmpty ? "" : rank}</span>
      {isCompany(EGameCompany.Experios) ? (
        <span className={classes["team-name-slot"]}>
          {isEmpty ? "" : companyName || "-"}
        </span>
      ) : null}
      <span className={classes["team-name-slot"]}>{isEmpty ? "" : name}</span>
      <span className={classes["hint-slot"]}>{isEmpty ? "" : hints}</span>
      <span className={classes["hint-slot"]}>{isEmpty ? "" : incorrect}</span>
      <span className={classes["hint-slot"]}>{isEmpty ? "" : result}</span>
    </div>
  );
};
export default TeamSlot;
