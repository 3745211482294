import React, { useState } from "react";
import classes from "./checkboxcontainer.module.scss";
import Checkbox from "../../checkbox/checkbox";
import RadioButton from "../../checkbox/radiobutton";
import { CheckboxProps } from "@material-ui/core/Checkbox";

interface ICheckboxContainer extends CheckboxProps {
  options: string[];
  isCheckbox?: boolean;
  OnValueChanged?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
}
const CheckboxContainer: React.FC<ICheckboxContainer> = ({
  options,
  isCheckbox = true,
  OnValueChanged = (e, value) => {},
  ...rest
}) => {
  const [activeRadioBtn, setActiveRadioBtn] = useState<string>("");
  const onChangeRadioButton = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setActiveRadioBtn(event.target.value);
  };
  const checkboxes = options.map((x, index) => {
    return isCheckbox ? (
      <Checkbox
        {...rest}
        style={{ width: "120px" }}
        key={index}
        value={x}
        label={x}
      ></Checkbox>
    ) : (
      <RadioButton
        {...rest}
        key={index}
        value={x}
        label={x}
        checked={activeRadioBtn === x}
        onChange={(e, value) => {
          onChangeRadioButton(e, value);
          OnValueChanged(e, value);
        }}
      ></RadioButton>
    );
  });
  return <div className={classes["checkbox-container"]}>{checkboxes}</div>;
};

export default CheckboxContainer;
