import React from "react";
import classes from "./checkbox.module.scss";
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

const WhiteCheckbox = withStyles({
  root: {
    color: "#eee",

    "&$checked": {
      color: green[500],
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

interface ICheckbox extends CheckboxProps {
  label: string;
  componentMargin?: string;
}

const CCheckbox: React.FC<ICheckbox> = ({
  label,
  componentMargin,
  style,
  ...rest
}) => {
  // const cStyle = { ...rest.style };
  return (
    <label className={`mr-3`} style={style}>
      <WhiteCheckbox {...rest}></WhiteCheckbox>
      {label}
    </label>
  );
};

export default CCheckbox;
