import React, { Fragment, useContext } from "react";
import { GamestateContext } from "../../../context/GameState/GameStateContext";
import { getTranslatedWord } from "../../../metadata/ButtonText";
import { SecToMin } from "../../../utilities/util";
import classes from "./imagepanel.module.scss";
import ZoomImagePanel from "./zoomable-image";
import AudioClip from "./audioClip";

interface IImagePanel extends React.ImgHTMLAttributes<HTMLImageElement> {
  linkImage: string;
  viewerImage: string;
  footerText?: string;
  audioPath?: string;
  defaultScale?: number;
  isDynamic?: boolean;
  onBackButtonClicked?: () => void;
}
const ImagePanel: React.FC<IImagePanel> = ({
  linkImage,
  viewerImage,
  footerText = undefined,
  audioPath = "",
  defaultScale = 1,
  isDynamic = false,
  onBackButtonClicked = null,
  ...rest
}) => {
  const { gameTime, gameLanguage } = useContext(GamestateContext);
  const [isZoomedOn, showZoomed] = React.useState(false);

  const getDynamicImage = (defaultImage: string): string => {
    if (isDynamic) {
      // loadNextImage();
      return `https://storage.googleapis.com/virtual-escape-assets/GameSteps/TT/BombTimer/${SecToMin(
        gameTime
      )}-01-min.webp`;
      // return `${process.env.PUBLIC_URL}/assests/GameFiles/BombTimer/${SecToMin(
      //   gameTime
      // )}-01-min.png`;
    }
    return defaultImage;
  };

  // const loadNextImage = async () => {
  //   try {
  //     await delay(55);
  //     const minutes = SecToMin(gameTime) - 1;
  //     const imageNo = Clamp(minutes, 0, 60);
  //     await CacheImages([
  //       `https://storage.googleapis.com/virtual-escape-assets/GameSteps/TT/BombTimer/${imageNo}-01-min.png`,
  //     ]);
  //   } catch (err) {}
  // };

  return (
    <Fragment>
      {isZoomedOn ? (
        <ZoomImagePanel
          image={getDynamicImage(viewerImage)}
          defaultScale={defaultScale}
          onClosePress={() => {
            showZoomed(false);
          }}
        ></ZoomImagePanel>
      ) : null}

      <div className={classes["content-container"]} style={rest.style}>
        {onBackButtonClicked ? (
          <i
            className="fas fa-arrow-left mt-3 ml-3"
            style={{
              position: "absolute",
              cursor: "pointer",
            }}
            onClick={() => {
              onBackButtonClicked();
            }}
          ></i>
        ) : // <Button
        //   text=""
        //   rightIcon={<i className="fas fa-arrow-left"></i>}
        //   style={{
        //     fontSize: "1em",
        //     alignSelf: "center",
        //     width: "50px",
        //     height: "40px",
        //     position: "absolute",
        //     borderRadius: "4px",
        //   }}
        //   onClick={() => {
        //     onBackButtonClicked();
        //   }}
        // ></Button>
        null}
        {linkImage === viewerImage ? (
          <img
            src={getDynamicImage(viewerImage)}
            alt=""
            style={{ minWidth: "25%", maxWidth: "100%", cursor: "pointer" }}
            onClick={() => {
              showZoomed(true);
            }}
          ></img>
        ) : (
          <a href={getDynamicImage(linkImage)} target="_blank" rel="noreferrer">
            <img
              src={getDynamicImage(viewerImage)}
              alt=""
              style={{ minWidth: "25%", maxWidth: "100%" }}
            ></img>
          </a>
        )}

        <div className={classes["content-container-text"]}>
          <span>
            {footerText === undefined
              ? getTranslatedWord("(click to enlarge)", gameLanguage)
              : footerText}
          </span>
        </div>
        <AudioClip header={""} path={audioPath}></AudioClip>
      </div>
    </Fragment>
  );
};

//export default ImagePanel;

export default React.memo(ImagePanel, function arePropsAreEqual(prev, next) {
  return (
    prev.audioPath === next.audioPath &&
    prev.footerText === next.footerText &&
    prev.linkImage === next.linkImage &&
    prev.viewerImage === next.viewerImage &&
    prev.isDynamic === next.isDynamic
  );
});
