type LogLevel = 'info' | 'warn' | 'error' | 'debug';

const logLevels: Record<LogLevel, LogLevel> = {
  info: 'info',
  warn: 'warn',
  error: 'error',
  debug: 'debug',
};

const logMessage = (level: LogLevel, message: string): void => {
  const timestamp = new Date().toISOString();
  if (logLevels[level]) {
    console[level](`${timestamp} - ${level.toUpperCase()}: ${message}`);
  } else {
    console.log(`${timestamp} - LOG: ${message}`);
  }
};

const info = (message: string): void => logMessage('info', message);
const warn = (message: string): void => logMessage('warn', message);
const error = (message: string): void => logMessage('error', message);
const debug = (message: string): void => logMessage('debug', message);

const logger = {
  info,
  warn,
  error,
  debug,
};

export default logger;

export const FormatMessageByCode = (code: string, message: string) => {
  return code + " -- " + message;
};


