import React, { useContext, useEffect, useState } from "react";
import classes from "./iframeviewer.module.scss";
import { getTranslatedWord } from "../../metadata/ButtonText";
import { GamestateContext } from "../../context/GameState/GameStateContext";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { FRIENDLY_SHIPS, ENEMY_SHIPS } from "../../constants";

interface IFrameViewerProps {
  src: string;
  footerText?: string;
  style?: CSSProperties;
  callback?: (data: string) => void
}

const IFrameViewer: React.FC<IFrameViewerProps> = ({
  src,
  footerText,
  style,
  callback
}) => {
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const { gameLanguage } = useContext(GamestateContext);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.origin === window.location.origin) {
        if (FRIENDLY_SHIPS.includes(event.data) || ENEMY_SHIPS.includes(event.data))
          callback?.(event.data)
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const handleLoad = () => {
    setIframeLoaded(true);
  };

  return (
    <div className={classes["content-container"]} style={style}>
      {!iframeLoaded && (
        <div
          style={{
            textAlign: "center",
          }}
        >
          Loading content...
        </div>
      )}

      <>
        <div style={{ width: "100%" }}>
          <div
            style={{
              position: "relative",
              paddingBottom: "56.25%",
              paddingTop: 0,
              height: 0,
            }}
          >
            <iframe
              title="Genially"
              frameBorder="0"
              width="1200"
              height="675"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                display: iframeLoaded ? "block" : "none",
              }}
              src={src}
              allowFullScreen={true}
              scrolling="yes"
              onLoad={handleLoad}
            ></iframe>{" "}
          </div>{" "}
        </div>
        <div
          className={classes["content-container-text"]}
          style={{ display: iframeLoaded ? "block" : "none" }}
        >
          <span>
            {footerText === undefined
              ? getTranslatedWord("(Press arrows to navigate)", gameLanguage)
              : footerText}
          </span>
        </div>
      </>
    </div>
  );
};

export default IFrameViewer;
